import React from "react";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/tools";

const ClubRoom = ({ club }) => {
  const navigate = useNavigate();

  const getTrend = (trend) => {
    let precent = formatNumber(Math.abs(trend) * 100) + "%";
    if (trend >= 0) {
      return (
        <div className="text-[#3fe51c] rounded-full text-right bg-[#2d7e1f] bg-opacity-50 h-[16px] px-1 green-arrow text-[10px]">
          <div className="ml-1 max-w-10 overflow-hidden overflow-ellipsis whitespace-nowrap h-[16px] flex items-center">
            {precent}
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-[#ff6203] rounded-full text-right bg-[#88332c] bg-opacity-50 h-[16px] px-1 red-arrow text-[10px]">
          <div className="ml-1 max-w-10 overflow-hidden overflow-ellipsis whitespace-nowrap h-[16px] flex items-center">
            {precent}
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className="m-[1%] rounded-lg flex flex-col bg-black bg-opacity-50 overflow-hidden cursor-pointer"
      onClick={() => {
        if (club.you_has === 0) {
          navigate("/club/" + club.owner.id);
        } else {
          navigate("/chat/" + club.owner.id);
        }
      }}
      style={{ flex: "0 0 48%", aspectRatio: "3/4" }}
    >
      <div className="relative">
        <img
          src={club.owner.avatar}
          alt="bg"
          className="w-full"
          style={{ height: 'calc(100% + 0.5rem)' }}
        />
        {club.you_has === 0 ? (
          <div className="absolute top-0 left-0 w-full  bg-white bg-opacity-20 z-10 flex items-center justify-center"
            style={{ height: 'calc(100% + 0.5rem)' }}>
            <div className="w-[33%] p-1 rounded-full bg-black bg-opacity-40" style={{ aspectRatio: "1/1" }}>
              {/*  bg-black bg-opacity-50 */}
              <img src="/images/lock.png" alt="lock" className="relative" />
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex-1 flex px-2 items-center justify-center relative top-[0.25rem]">
        <div
          className="w-9 h-9 flex rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid 
         flex-none"
        >
          <img src={club.owner.avatar} alt="avatar" />
        </div>
        <div className="flex-1 flex flex-col pl-1 text-[0.9rem]">
          <div className="flex items-center">
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap max-w-20">
              <strong>{club.owner.name}</strong>
            </div>
            {club.owner.twitter_name ? (
              <div
                style={{
                  background:
                    "url(/images/twitter.png) no-repeat center center",
                  backgroundSize: "100%",
                }}
                className="p-1 mx-1 text- flex items-center rounded-full m-[1px] h-[14px] w-[14px] justify-center"
              ></div>
            ) : null}
          </div>
          <div className="flex items-center text-sm">
            <div className="flex-1 flex items-center">
              {/* <div className="w-4 h-4 mr-1  p-1 flex items-center rounded-full bg-white ">
                <img src="/svg/eth.svg" alt="eth" />
              </div> */}
              <img
                src="/images/eth.png"
                alt=""
                className="h-[16px] w-[16px] mr-1"
              />
              <div className="overflow-hidden overflow-ellipsis whitespace-nowrap w-10 text-[10px]">
                {club.value}
              </div>
            </div>
            <div className="flex h-4 items-center justify-center flex-row">
              <div className="flex">
                {/* {getTrend(club.previous_price !== 0 ? (club.price - club.previous_price) / club.previous_price : 0)} */}
                {getTrend(club.price_rate)}
              </div>
            </div>
            {/* <div className="flex-1">{getTrend(club.trend)}</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubRoom;
