import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import Modal from "./Modal";
import { get, post } from "../utils/request";
import { truncateDecimal, formatNumber } from "../utils/tools";
import { ToastContainer, toast } from 'react-toastify';

const QuestGallery = ({ quests, address, avatar, name, level, changeStatus, energy }) => {
  const earnRef = useRef(null)
  const rateRef = useRef(null)
  const currentRef = useRef(null)
  const [index, setIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(null);
  const [direction, setDirection] = useState(0); // 0 for right, 1 for left
  const [userInfo, setUserInfo] = useState({ avatar: '', name: '', club: { level: 0, address: '' }, account: "" });
  const [earnings, setEarnings] = useState({
    "club": 0,
    "manager": 0,
    "owner": 0,
    "you": 0,
    total_keys: 0,
    you_keys: 0
  })
  const navigate = useNavigate();
  const [rate, setRate] = useState(1);
  const [totalRate, setTotalRate] = useState(1);
  const [flag, setFlag] = useState(false);


  const slideTo = (newIndex) => {
    if (newIndex < 0) {
      setDirection(1);
      setNextIndex(quests.length - 1);
    } else if (newIndex >= quests.length) {
      setDirection(0);
      setNextIndex(0);
    } else {
      setDirection(newIndex > index ? 0 : 1);
      setNextIndex(newIndex);
    }
  };

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo !== null) {
      setUserInfo(userInfo)
    }
    getRate()
  }, [])

  useEffect(() => {
    if (quests.length) {
      let { id } = quests[0]
      getEarnings(id)
    }
  }, [])

  useEffect(() => {
    if (nextIndex !== null) {
      setIndex(nextIndex);
      setNextIndex(null); // Reset nextIndex
    }
  }, [direction, nextIndex]);

  useEffect(() => {
    if (quests.length) {
      let { id, rate: itemRate } = quests[index]
      getEarnings(id)
      setTotalRate(itemRate * rate)
    }
  }, [index, rate])

  const getEarnings = async (id) => {
    let postData = {
      "club_address": address,
      "quest_id": id,
      "user_account": userInfo.account
    }
    let result = await post('/quest/reward', postData)
    let { code, data } = result
    if (code === 1000) {
      setEarnings(data)
    }
  }

  const getRate = async () => {
    let result = await get('/trade/rate')
    let { code, data } = result
    if (code === 1000) {
      setRate(data)
    }
  }

  const variants = {
    enter: (direction) => ({
      x: direction === 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction === 0 ? -1000 : 1000,
      opacity: 0,
    }),
  };

  const unlock = async (index) => {
    setFlag(true)
    let { id } = quests[index]
    let postData = {
      "club_address": address,
      "quest_id": id,
      "user_account": userInfo.account
    }
    try {
      let result = await post('/quest/unlock', postData)
      let { code, msg } = result
      if (code === 1000) {
        toast.success("Successfully unlocked the task!")
        changeStatus(index)
        // refresh()
      } else {
        toast.error(msg)
      }
    } catch (error) {

    } finally {
      setFlag(false)
    }

  }

  const active = async (index) => {
    setFlag(true)
    let { id } = quests[index]
    let postData = {
      "club_address": address,
      "quest_id": id,
      "user_account": userInfo.account
    }
    try {
      let result = await post('/quest/active', postData)
      let { code, msg } = result
      if (code === 1000) {
        //领取任务成功
        toast.success("Successfully received the task!")
        setTimeout(() => {
          navigate(-1)
        }, 500);
      } else {
        toast.info(msg)
      }
    } catch (error) {

    } finally {
      setFlag(false)
    }
  }

  const openEarnModal = () => {
    if (earnRef.current) {
      earnRef.current.togglePopup()
    }
  }

  const openRateModal = () => {
    if (rateRef.current) {
      rateRef.current.togglePopup()
    }
  }

  const openCurrentModal = () => {
    if (currentRef.current) {
      currentRef.current.togglePopup()
    }
  }

  return (
    <div
      className="flex items-center justify-center"
      style={{ position: "relative", width: "100%", height: "100%" }}
    >

      <img className="w-2 left-3 fixed top-7" src="/images/back.png" alt="" onClick={() => { navigate(-1) }}
        style={{ zIndex: 9999 }} />
      <ToastContainer pauseOnFocusLoss={false} tainer theme="dark" position="top-center" />
      <div className="absolute left-0 top-0 h-14 w-full flex items-center" style={{
        zIndex: 9,
        // backgroundImage: 'linear-gradient(to bottom,#030303f2,#1f1e1b2c)'//1c
      }}>
      </div>
      <div className="flex-1 relative h-full overflow-y-auto w-[100vw]">
        {quests.length ?
          <AnimatePresence custom={direction}>
            <motion.div
              key={index}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.5 },
              }}
              style={{
                position: "absolute",
                height: '100%',
                width: '100%',
                backgroundImage: `url(${quests[index].image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: 'cover',
                zIndex: 9
              }}
            ></motion.div>

            <motion.div
              initial={{ scale: 0.7 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.5 }}
              className="absolute w-full h-full"
            >
              <div className="h-14 relative top-2 z-10 w-full flex items-center justify-center">
                <div className="w-[70%] rounded-xl  bg-[#120f0f] border-2 border-[#555] px-2 h-10 text-center
                flex items-center"  style={{ whiteSpace: 'nowrap', overflow: 'auto' }}>
                  <strong>{quests[index].title}</strong>
                </div>
              </div>
              <div className="z-10 text-sm mx-4 rounded-lg top-4 p-4 min-h-8 relative bg-white bg-opacity-15 break-all
               text-[#a59e91]">
                {quests[index].describe}
              </div>

              <div
                className="w-full absolute z-10 flex flex-col items-center"
                style={{ left: 0, bottom: "calc(35% + 6rem)" }}
              >
                <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-[#120f0f] bg-opacity-50 border-solid border-2">
                  <img src={avatar} alt="avatar" className="w-16 h-16" />
                </div>
                <div className="flex flex-row bg-[#3d3434] bg-opacity-80 px-2 py-1 rounded-full">
                  <div style={{
                    background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain",
                  }}
                    className="flex items-center text-xs rounded-full h-[20px] w-[20px] justify-center text-white"
                  >
                    <strong>{level}</strong>
                  </div>
                  <div className="text-sm ml-2">{name}</div>
                </div>
              </div>
              <div className="z-10 w-full text-sm flex px-4 h-[35%] absolute bottom-20 text-[#979693]">
                <div className="w-full h-full flex flex-col relative">
                  {!quests[index].unlock_status ? (
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000a1] z-[-1] flex items-center justify-center rounded-2xl">
                      <div className="w-20 p-1 h-20 rounded-full bg-black bg-opacity-40">
                        <img src="/images/lock.png" alt="lock" />
                      </div>
                    </div>
                  ) : null}
                  <div className="w-full flex flex-col flex-[3] bg-[#120f0f] bg-opacity-60 rounded-t-2xl py-2 px-3">
                    <div className="flex flex-1 items-center">
                      <div className="text-left flex-[2]">Level Required</div>
                      <div className="text-right flex-[1] flex items-center justify-end">
                        <div style={{
                          background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "100%",
                        }}
                          className="flex items-center text-xs rounded-full h-[20px] w-[20px] justify-center text-white"
                        >
                          <strong>{quests[index].level}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 items-center">
                      <div className="text-left flex-[2]">$TOKEN Production Rate </div>
                      <div className="justify-end items-center flex-[1] flex text-white">
                        {rate * 100 + "%"}
                        <div className="border flex items-center justify-center rounded-full h-[20px] w-[20px] 
                            text-[#959496] border-[#959496] ml-1"
                          onClick={openRateModal}>
                          ?
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 items-center">
                      <div className="text-left flex-[2]">Current Efficiency</div>
                      <div className="justify-end items-center flex-[1] flex text-white">
                        {quests[index].rate * 100 + "%"}
                        <div className="border flex items-center justify-center rounded-full h-[20px] w-[20px] 
                            text-[#959496] border-[#959496] ml-1"
                          onClick={openCurrentModal}>
                          ?
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 items-center">
                      <div className="text-left flex-[2]">Mission Duration</div>
                      <div className="justify-end items-center flex-[1] flex text-white">
                        <div className="h-4 w-4 mr-1 text-center rounded-full flex items-center justify-center">
                          <img src="/svg/clock.svg" alt="clock" />
                        </div>
                        {quests[index].duration + "h"}
                        <div className="h-4 w-4 ml-1"></div>
                      </div>
                    </div>
                    <div className="flex flex-1 items-center">
                      <div className="text-left flex-[2]">Level 1 Yield</div>
                      <div className="justify-end items-center flex-[1] flex text-white">
                        <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-2" />
                        {quests[index].yield + "/hr"}
                        <div className="h-4 w-4 ml-1"></div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col flex-[2] bg-[#120f0f] bg-opacity-85 rounded-b-2xl pb-2 px-3">
                    <div className="flex flex-1 items-center">
                      <div className="text-left flex-[1]">Total Club Earnings</div>
                      <div className="justify-end items-center flex-[1] flex text-white">
                        <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-2" />
                        {earnings.club > 1 ? formatNumber((earnings.club * totalRate) / 0.4) :
                          truncateDecimal((earnings.club * totalRate) / 0.4, 5)}
                        <div className="border-none flex h-[20px] w-[20px]   ml-2">
                        </div>
                      </div>
                    </div>
                    {quests[index].type === 0 ? <div className="flex flex-1 items-center">
                      <div className="text-left flex-[2]">Special Earnings</div>
                      <div className="justify-end items-center flex-[1] flex text-white">
                        <div className="h-4 w-4 mr-1 text-center rounded-full flex items-center justify-center p-[2px]">
                          <img src="/images/energy-yellow.png" alt="" />
                        </div>
                        50
                        <div className="h-4 w-4 ml-1"></div>
                      </div>
                    </div> : <div className="flex flex-1 items-center">
                      <div className="text-left flex-[1]">Club Manager Earnings</div>
                      <div className="justify-end items-center flex-[1] flex text-white">
                        <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-2" />
                        {earnings.manager > 1 ? formatNumber(earnings.manager * totalRate)
                          : truncateDecimal((earnings.manager * totalRate), 5)}
                        <div className="border flex items-center justify-center rounded-full h-[20px] w-[20px] 
                            text-[#959496] border-[#959496] ml-2"
                          onClick={openEarnModal}>
                          ?
                        </div>
                      </div>
                    </div>}
                    <div className="flex flex-1 items-center">
                      <div className="text-left flex-[1]">My Expected Earnings</div>
                      <div className="justify-end items-center flex-[1] flex text-white">
                        <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-2" />
                        {earnings.you > 1 ? formatNumber(earnings.you * totalRate) :
                          truncateDecimal((earnings.you * totalRate), 5)}
                        <div className="border flex items-center justify-center rounded-full h-[20px] w-[20px] 
                            text-[#959496] border-[#959496] ml-2"
                          onClick={openEarnModal}>
                          ?
                        </div>
                      </div>
                    </div>

                    {/* <div className="flex flex-1 items-center justify-center bg-black rounded-full">
                    Dispatched Clubs ({quests[index].unlock} / 10)
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="h-12 flex-none absolute bottom-0 w-full px-4 my-4 z-10">
                {level < quests[index].level || (index > 0 && !quests[index - 1].unlock_status) ?
                  <Button className={`w-full h-full flex text-sm rounded-full items-center justify-center text-white px-1`}
                    disabled>
                    {!quests[index - 1].unlock_status ?
                      <strong>REQUIRE UNLOCK PREVIOUS MISSION</strong> :
                      <strong>REQUIRE LEVEL {quests[index].level} TO START</strong>
                    } </Button> :
                  (!quests[index].unlock_status ?
                    <Button className={`w-full h-full flex text-sm rounded-full items-center justify-center`}
                      onClick={() => unlock(index)} disabled={flag}>
                      <img src="/images/ttt.png" alt="energy" className="w-[20px] h-[20px] mr-[6px]" />
                      <strong>{quests[index].unlock} TO UNLOCK</strong>
                    </Button> :
                    <Button className={`w-full h-full flex text-sm rounded-full items-center justify-center`}
                      onClick={() => active(index)} disabled={flag || quests[index].energy > energy}
                    >
                      <strong>{quests[index].energy > energy ? "LACK OF ENERGY" : "RECEIVE MISSION"}</strong>
                      <div className="w-[20px] h-[20px] ml-2 mr-1 p-[5px] flex items-center rounded-full bg-[#332626]">
                        <img src="/images/energy-yellow.png" alt="energy" />
                      </div>
                      <strong className="text-[#cc3054]">-{quests[index].energy} </strong>
                    </Button>)
                }
              </div>
            </motion.div>
          </AnimatePresence>
          : null}
        <div className="absolute left-0 top-[45vh]" style={{ zIndex: 99 }}>
          <button onClick={() => slideTo(index - 1)}>
            <img className="w-10" src="/svg/left.svg" alt="left" />
          </button>
        </div>
        <div className="absolute right-0 top-[45vh]" style={{ zIndex: 99 }}>
          <button onClick={() => slideTo(index + 1)}>
            <img className="w-10" src="/svg/right.svg" alt="right" />
          </button>
        </div>


        <Modal ref={earnRef} closeIcon={false} title="EARNINGS DETAIL">
          <div className="flex flex-col items-center my-4 w-full text-sm">
            <div className="w-full text-left break-all">The total club earnings will split 40% to the manager,
              40% among all members and 20% to the club owner.</div>
            <div className="flex flex-col w-full my-1">
              <div className="flex justify-between w-full my-1">
                <div>Total Club Earnings</div>
                <div className="flex items-center">
                  <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                  {earnings.manager / 0.4 > 1 ? formatNumber((earnings.manager * totalRate) / 0.4) :
                    truncateDecimal((earnings.manager * totalRate) / 0.4, 5)}
                </div>
              </div>
              <div className="flex justify-between w-full my-1">
                <div className="flex flex-[2]">Manager's Share</div>
                <div className="flex flex-1 justify-center items-center">40%</div>
                <div className="flex items-center flex-1 justify-end">
                  <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                  {earnings.manager > 1 ? formatNumber(earnings.manager * totalRate) :
                    truncateDecimal(earnings.manager * totalRate, 5)}
                </div>
              </div>
              <div className="flex justify-between w-full my-1">
                <div className="flex flex-[2]">Club Owner's Shares</div>
                <div className="flex flex-1 justify-center items-center">20%</div>
                <div className="flex items-center flex-1 justify-end">
                  <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                  {earnings.owner > 1 ? formatNumber(earnings.owner * totalRate) :
                    truncateDecimal(earnings.owner * totalRate, 5)}
                </div>
              </div>
              <div className="flex justify-between w-full my-1 items-center">
                <div className="flex flex-col flex-[2]">
                  <div>Key Holders Share</div>
                  <div>You Hold {earnings.you_keys}/{earnings.total_keys} Keys</div>
                </div>
                <div className="flex flex-1 justify-center items-center">
                  {earnings.total_keys === 0 ? 0 : formatNumber(40 * (earnings.you_keys / earnings.total_keys))}%</div>
                <div className="flex items-center flex-1 justify-end">
                  <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                  {earnings.total_keys === 0 ? 0 : (earnings.manager * (earnings.you_keys / earnings.total_keys) > 1 ?
                    formatNumber(earnings.manager * (earnings.you_keys / earnings.total_keys) * totalRate) :
                    truncateDecimal((earnings.manager * (earnings.you_keys / earnings.total_keys) * totalRate), 5))}
                </div>
              </div>
              <div className="border-[#ccc] border-t mt-2"></div>

              <div className="flex justify-between w-full my-2">
                <div className="flex flex-[2]">My Earnings</div>
                <div className="flex flex-1 justify-center">
                  {earnings.you === 0 ? "-" : formatNumber(earnings.you / (earnings.manager / 0.4) * 100) + '%'}</div>
                <div className="flex items-center flex-1 justify-end">
                  <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                  {earnings.you > 1 ? formatNumber(earnings.you * totalRate) :
                    truncateDecimal(earnings.you * totalRate, 5)}
                </div>
              </div>
            </div>
          </div>
        </Modal >

        <Modal ref={rateRef} closeIcon={false}>
          <div className="flex flex-col items-center my-4 w-full text-xs">
            <div className="text-sm">$TTT production decreases into half each time the emission milestone is reached.</div>
            <div className="border border-1 w-full my-4"></div>
            <table className="w-full border-collapse rate-table">
              <thead>
                <tr>
                  <th>STAGE</th>
                  <th>$TTT PRODUCED</th>
                  <th>$TTT PRODUCTION RATE</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-[purple]">
                  <td>1</td>
                  <td>0-500.0m</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>500.0m-750.0m</td>
                  <td>50%</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>750.0m-875.0m</td>
                  <td>25%</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>875.0m-937.5m</td>
                  <td>12.5%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>

        <Modal ref={currentRef} >
          <div className="flex flex-col items-center my-4 w-full text-xs">
            <div className="text-sm">Work efficiency determines your $TTT earnings. The more clubs that are completing the same mission, the lower the efficiency, thus reducing $TTT production. This motivates clubs to unlock new missions to increase $TTT earnings.</div>
            <div className="border border-1 w-full my-4"></div>
            <div className="overflow-y-auto max-h-[300px] rate-table">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th>STAGE</th>
                    <th >CLUB NUMBER</th>
                    <th>$TTT PRODUCTION RATE</th>
                  </tr>
                </thead>
                <tbody>
                  {quests && quests.length ? quests[index].rules.map((i, d) => (
                    <tr key={d} className={i.reward_rate === quests[index].rate ? `bg-[purple]` : ''}>
                      <td>{i.id}</td>
                      <td>{i.min_participants}-{i.max_participants < 99999 ? i.max_participants : '100k+'}</td>
                      <td>{i.reward_rate * 100}%</td>
                    </tr>
                  )) : null}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>

      </div>

    </div >
  );
};
// LevelRequired: 3,
// ProductionRate: 1,
// CurrentEfficiency: 1,
// MissionDuration: 10,
// Yield: 3000,
// ClubEarnings: 30000,
// MyEarnings: 3666,
// DispatchedClubs: 0,
export default QuestGallery;
