import React, { useEffect, useContext, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const { ready, authenticated, login, logout } = usePrivy();
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const [inviter, setInviter] = useState('');

  useEffect(() => {
    let loop, failLoop, count = 0
    if (ready && authenticated) {
      loop = setInterval(() => {
        count++
        let token = localStorage.getItem("token"), user = JSON.parse(localStorage.getItem("userInfo"))
        if (token !== null) {
          if (user) {
            setCount(user.club.key);
            if (user.invite_user !== null) { setInviter(user.invite_user.id) }
          }
          setLoaded(true);
          clearInterval(loop);
        }
        if (count >= 12) {
          clearInterval(loop);
        }
      }, 1000);

      failLoop = setInterval(() => {
        count++
        if (localStorage.getItem("fail") === "true" || count >= 12) {
          toast.error('Not Authenticated!');
          localStorage.clear()
          logout()
          clearInterval(failLoop);
        }
      }, 2000);
    }
    return () => {
      clearInterval(loop);
      clearInterval(failLoop)
    };
  }, [ready, authenticated]);


  if (!ready) {
    return null;
  } else {
    return ready && authenticated && loaded ? (
      inviter ?
        (count ? <Navigate to="/main" replace={true} /> : <Navigate to="/start" replace={true} />)
        : <Navigate to="/invite" replace={true} />
    ) : (
      <div
        className="flex w-full h-full items-center justify-center hire max-w-[768px] bg-cover bg-no-repeat"
        style={{ backgroundImage: "url(/images/home.png)" }}
      >
        <div className="inner-warning text-lg py-2">
          <strong>For internal testing and development purposes only.</strong>
        </div>
        <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
        <div className="fixed bottom-20">
          <button className="w-40 bg-[#fcb833] h-12 rounded-full border-2 shadow-md"
            onClick={() => { login() }}>
            <strong className="text-2xl text-black">START</strong>
          </button>
        </div>
      </div>
    );
  }
};

export default Index;
