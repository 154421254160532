import React, { useState, useEffect } from 'react';

const CountTimer = ({ lootDate, notice, className, children }) => {
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function calculateTimeRemaining() {
        const now = new Date();
        const targetDate = new Date(lootDate);
        const difference = targetDate - now;

        if (difference <= 0) {
            notice(true)
            return { hours: 0, minutes: 0, seconds: 0 };
        }

        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return { hours, minutes, seconds };
    }

    return (
        <div className={`${className ? className : ""}`}>
            {children}
            {timeRemaining.hours >= 10 ? timeRemaining.hours : '0' + timeRemaining.hours}:
            {timeRemaining.minutes >= 10 ? timeRemaining.minutes : '0' + timeRemaining.minutes}:
            {timeRemaining.seconds >= 10 ? timeRemaining.seconds : '0' + timeRemaining.seconds}
        </div>
    );
};



export default CountTimer;
