import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, } from "react-router-dom";
import { post } from '../utils/request'
import { ToastContainer, toast } from 'react-toastify';


const Looting = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from;
    const to = location.state?.to;
    const pay = location.state?.pay;
    const refund = location.state?.refund;

    const [progress, setProgress] = useState(0)
    const [code, setCode] = useState(0)
    const [msg, setMsg] = useState('')

    useEffect(() => {
        let interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return prevProgress;
                } else {
                    return prevProgress + 10;
                }
            });
        }, 200);
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        getResult()
    }, [])

    useEffect(() => {
        if (progress === 100 && code && msg) {
            if (code === 1001) {
                navigate('/lootfail', { state: { from, to } })
            } else if (code === 1000) {
                navigate('/lootok', { state: { from, to, refund } })
            } else {
                toast.info(msg)
                setTimeout(() => {
                    navigate(-1)
                }, 2000);
            }
        }
    }, [progress, code, msg])

    const getResult = async () => {
        let result = await post('/club/loot', {
            from: from.address, pay, to: to.address
        })
        let { code, data, msg } = result
        setCode(code)
        if (code === 20001) {
            setMsg(msg)
        } else {
            setMsg(data)
        }
    }

    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-black bg-cover bg-no-repeat bg-top"
            style={{ backgroundImage: "url(/images/looting.png)" }} >
            <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
            <div className="w-full relative flex-none">
                <div className="fixed top-6 left-0 text-white w-full text-lg">
                    <div className="relative flex items-center justify-center">
                        <strong>LOOTING...</strong>
                        {/* <div className="absolute left-[10px]" onClick={() => navigate(-1)}>
                            <img className="w-2" src="/images/back.png" alt="back" />
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="fixed bottom-10 p-4 w-full flex flex-col items-center px-6 max-w-[768px]">
                <img src="/images/loading.png" alt="" className="w-[80px]" />
                <div className="flex h-4 my-4 text-xs bg-[#2e2e2e] rounded-full w-full">
                    <div style={{ width: `${progress}%`, backgroundImage: "linear-gradient(to right, #e14851, #e7ae2f)" }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center
                         rounded-full transition-width duration-500 ease-in-out">
                    </div>
                </div>
                <div className="text-base">PROGRESS:{progress}%</div>
            </div>

        </div >
    );
};

export default Looting;
