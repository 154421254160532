import React from "react";
// start #ec3468
// end #410483
const WhiteContainer = (props) => {
  return (
    <div
      style={{ lineHeight: "28px", ...props.style }}
      onClick={props.onClick}
      className={`h-[28px] text-sm bg-[#a294a3] bg-opacity-30 text-center text-white rounded-full ${
        props.className ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
};

export default WhiteContainer;
