import React from "react";

const CleanTab = ({ className, tabs, active, onSelect }) => {
    return (
        <div
            className={`h-8 max-w-60 items-center bg-transparent flex-1 flex text-base
            text-white rounded-full ${className ? className : ""}`}
        >
            {tabs.map((tab, index) => (
                <div
                    key={tab.name}
                    onClick={() => onSelect(index)}
                    style={{
                        color: index === active ? "white" : "#88818a",
                    }}
                    className={`h-full flex-1 cursor-pointer flex items-center justify-start
                     rounded-full`}//7910a1
                >
                    {tab.name}
                </div>
            ))}
        </div>
    );
};

export default CleanTab;
