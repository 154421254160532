import React, { useState, useEffect, useRef } from "react";
import WhiteContainer from "../../components/WhiteContainer";
import Button from "../../components/Button";
import CleanTab from "../../components/CleanTab";
import Modal from "../../components/Modal";
import ClubOwnItem from "../../components/ClubOwnItem";
import ClubAllItem from "../../components/ClubAllItem";
import { useNavigate, useLocation } from "react-router-dom";
import { get, post } from "../../utils/request"
import Activity from "../../components/Activity";
import { useWallets } from "@privy-io/react-auth";
import { ethers } from "ethers";
import contractABI from "../../abi/jumpup";
import { formatNumber, truncateDecimal } from "../../utils/tools"
import { REACT_APP_CONTRACT } from '../../config'
import { ToastContainer, toast } from "react-toastify";

const tabsList = [
  {
    name: "My Clubs",
  },
  {
    name: "All Clubs",
  },
  {
    name: "Activities",
  },
];

const Mission = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const otherActive = location.state?.active;
  const scrollRef = useRef(null)
  const clubScrollRef = useRef(null)
  const actScrollRef = useRef(null)
  const ref = useRef(null)
  const [active, setActive] = useState(otherActive ? otherActive : 0);
  const [userInfo, setUserInfo] = useState({
    avatar: "/images/avatar.png",
    pool: 0,
    club: {
      "rank": 0,
      "level": 1,
      "value": 0,
      "up": 0,
      address: ''
    }
  });
  const [clubList, setClubList] = useState([])
  const [ownList, setOwnList] = useState([])
  const [activityList, setActivityList] = useState([])
  const [page, setPage] = useState(1);
  const [clubPage, setClubPage] = useState(1);
  const [actPage, setActPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [clubTotal, setClubTotal] = useState(0);
  const [actTotal, setActTotal] = useState(0);
  const [isScrolledToNewBottom, setIsScrolledToNewBottom] = useState(false);
  const [keyCount, setKeyCount] = useState(0)
  const [manager, setManager] = useState({
    owner: { avatar: '/images/avatar.png' },
    address: ''
  })
  const [visible, setVisible] = useState(false)
  const [balance, setBalance] = useState(0)
  const [buyEth, setBuyEth] = useState(0)
  const [totalBuyEth, setTotalBuyEth] = useState(0)
  const [isBuying, setIsBuying] = useState(false);
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo !== null) {
      getUserData(userInfo.id)
    }
  }, [])

  useEffect(() => {
    const scrollableDiv = scrollRef.current;
    const handleScroll = () => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;

      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (!isScrolledToNewBottom) {
          setPage(prePage => (prePage + 1))
          console.log('滚动到了底部');
          setIsScrolledToNewBottom(true);
        }
      } else {
        setIsScrolledToNewBottom(false);
      }
    };
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolledToNewBottom]);

  useEffect(() => {
    const scrollableDiv = clubScrollRef.current;
    const handleScroll = () => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;

      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (!isScrolledToNewBottom) {
          setClubPage(prePage => (prePage + 1))
          console.log('滚动到了底部');
          setIsScrolledToNewBottom(true);
        }
      } else {
        setIsScrolledToNewBottom(false);
      }
    };
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolledToNewBottom]);

  useEffect(() => {
    const scrollableDiv = actScrollRef.current;
    const handleScroll = () => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;

      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (!isScrolledToNewBottom) {
          setActPage(prePage => (prePage + 1))
          console.log('滚动到了底部');
          setIsScrolledToNewBottom(true);
        }
      } else {
        setIsScrolledToNewBottom(false);
      }
    };
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolledToNewBottom]);

  useEffect(() => {
    if (page > 1) getOwnData()
  }, [page])

  useEffect(() => {
    if (clubPage > 1) getClubData()
  }, [clubPage])

  useEffect(() => {
    if (actPage > 1) getActivities()
  }, [actPage])

  useEffect(() => {
    if (active === 0) {
      getInitOwnData()
      setClubPage(1)
      setActPage(1)
    } else if (active === 1) {
      getInitClubData()
      setPage(1)
      setActPage(1)
    } else {
      getInitActivities()
      setPage(1)
      setClubPage(1)
    }
  }, [active])

  useEffect(() => {
    if (!visible) {
      setKeyCount(0)
      setBuyEth(0)
      setTotalBuyEth(0)
    } else {
      setBuyInfo()
    }
  }, [visible])

  useEffect(() => {
    getBalance()
  }, [embeddedWallet])

  useEffect(() => {
    if (keyCount > 0 && ref.current) {
      ref.current.togglePopup();
    }
  }, [keyCount])

  const getUserData = async (id) => {
    let result = await get("/user/" + id);
    let { code, data } = result;
    if (code === 1000) {
      setUserInfo(data);
    }
  };

  const getInitOwnData = async () => {
    let result = await get(`/club/relate?page=1&size=${size}`)
    let { code, data } = result
    if (code === 1000) {
      let { total, list } = data
      setOwnList(list)
      setTotal(total)
    }
  }

  const getOwnData = async () => {
    if (page === 1) {
      let result = await get(`/club/relate?page=${page}&size=${size}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        setOwnList(list)
        setTotal(total)
      }
    } else if ((page - 1) * size < total) {
      let result = await get(`/club/relate?page=${page}&size=${size}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        let tempList = [...ownList]
        let currentList = tempList.concat([...list])
        setOwnList(currentList)
        setTotal(total)
      }
    }
  }

  const getInitClubData = async () => {
    let result = await get(`/club/other?page=1&size=${size}`)
    let { code, data } = result
    if (code === 1000) {
      let { total, list } = data
      setClubList(list)
      setClubTotal(total)
    }
  }

  const getClubData = async () => {
    if (clubPage === 1) {
      let result = await get(`/club/other?page=${clubPage}&size=${size}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        setClubList(list)
        setClubTotal(total)
      }
    } else if ((clubPage - 1) * size < clubTotal) {
      let result = await get(`/club/other?page=${clubPage}&size=${size}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        let tempList = [...clubList]
        let currentList = tempList.concat([...list])
        setClubList(currentList)
        setClubTotal(total)
      }
    }
  }

  const getInitActivities = async () => {
    let result = await get(`/user/activity?page=1&size=${size}&filter=${userInfo.club.address}`)
    let { code, data } = result
    if (code === 1000) {
      let { total, list } = data
      setActivityList(list)
      setActTotal(total)
    }
  }

  const getActivities = async () => {
    if (actPage === 1) {
      let result = await get(`/user/activity?page=${actPage}&size=${size}&filter=${userInfo.club.address}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        setActivityList(list)
        setActTotal(total)
      }
    } else if ((actPage - 1) * size < actTotal) {
      let result = await get(`/user/activity?page=${actPage}&size=${size}&filter=${userInfo.club.address}`)
      // ${userInfo.club.address}
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        let tempList = [...activityList]
        let currentList = tempList.concat([...list])
        setActivityList(currentList)
        setActTotal(total)
      }
    }
  }

  const getBalance = async () => {
    try {
      if (typeof embeddedWallet !== "undefined") {
        const provider = await embeddedWallet?.getEthereumProvider();
        const balance = await provider?.request({
          method: 'eth_getBalance',
          params: [embeddedWallet?.address, 'latest'], // 'latest' 表示最新的区块
        });
        const balanceInEth = ethers.formatEther(balance);
        setBalance(parseFloat(balanceInEth))
      }
    } catch (error) {
      // console.error(error)
    }
  }

  const setBuyInfo = async () => {
    try {
      const provider = await embeddedWallet.getEthersProvider();
      const signer = provider?.getSigner();
      const contract = new ethers.Contract(
        REACT_APP_CONTRACT,
        contractABI, signer
      );
      console.log('contract:' + REACT_APP_CONTRACT)
      console.log('address:' + manager.address)
      console.log('keyCount:' + keyCount)
      const price = await contract.getBuyPrice(
        // eslint-disable-next-line no-undef
        manager.address, BigInt(keyCount)
      );
      const totalPrice = await contract.getBuyPriceAfterFee(
        // eslint-disable-next-line no-undef
        manager.address, BigInt(keyCount)
      );
      const buyBalanceInEth = ethers.formatEther(price);
      const buyTotalEth = ethers.formatEther(totalPrice);
      setBuyEth(buyBalanceInEth)
      setTotalBuyEth(buyTotalEth)
    } catch (error) {

    }
  }

  const buy = async () => {
    try {
      setIsBuying(true);
      const provider = await embeddedWallet.getEthersProvider();
      const signer = provider?.getSigner();
      const contract = new ethers.Contract(
        REACT_APP_CONTRACT,
        contractABI, signer
      );
      const price = await contract.getBuyPriceAfterFee(
        // eslint-disable-next-line no-undef 
        manager.address, BigInt(keyCount)
      );
      console.log('contract:' + REACT_APP_CONTRACT)
      console.log('address:' + manager.address)
      console.log('walletAddress:' + embeddedWallet?.address)
      console.log('buyAmount:' + keyCount)
      console.log("price:" + price)
      const txResponse = await contract.buyShares(
        // eslint-disable-next-line no-undef 
        manager.address, BigInt(keyCount), { from: embeddedWallet?.address, value: price }
      );
      console.log("Transaction sent:", txResponse.hash);
      // await txResponse.wait();
      let postData = {
        club_address: manager.address,
        key_num: keyCount,
        key_price: -(totalBuyEth),
        transaction: txResponse.hash,
      };
      let result = await post("/trade/buy", postData);
      let { code, data } = result;
      if (code === 1000) {
        ref.current.togglePopup()
        //访问成功
      }
    } catch (error) {
      console.error(error);
      toast.error('Purchase canceled or failed, please confirm the wallet amount or buy your own key first!')
    } finally {
      getInitOwnData()
      getInitClubData()
      setIsBuying(false);
    }
  };

  const buySelf = () => {
    navigate('/start')
    // setKeyCount(1)
    // setManager({ address: userInfo.club.address, owner: { avatar: userInfo.avatar } })
  }

  return (
    <div className="h-full flex flex-col bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: "url(/images/mission.png)" }}>
      <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
      <div className="py-2 px-1 flex items-center bg-black bg-opacity-50 justify-around" onClick={() => { navigate('/main/profile') }}>
        <div className="w-12 h-12 flex flex-none items-center justify-center rounded-full border-white overflow-hidden 
        bg-black bg-opacity-50 border-solid border-2 box-border">
          <img src={userInfo.avatar} alt="avatar" className="w-12" />
        </div>
        <WhiteContainer className="flex w-24 relative items-center rounded-full flex-none ml-2">
          <div
            className="left-[0px] top-[0px] absolute h-[28px] w-[28px] flex items-center justify-center text-lg"
            style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
          >
            <strong>{userInfo.club.level}</strong>
          </div>
          <div className="flex flex-1 pl-[30px] pr-[26px] justify-center">
            {formatNumber(balance)}
          </div>
          <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] absolute right-[4px]' />
        </WhiteContainer>
        <WhiteContainer className="w-20 relative rounded-full flex ml-2 flex-none">
          <div className="bg-button-up p-[1px] flex items-center absolute top-[-1px] left-[-1px] rounded-full h-[30px] w-[30px]">
            <img src="/images/up.png" alt="" />
          </div>
          <div className="flex flex-1 pl-[30px] justify-center">{formatNumber(userInfo.club.up)}</div>
        </WhiteContainer>
        <WhiteContainer className="flex-1 flex relative items-center rounded-full ml-2">
          <div className="bg-[#eaa222] p-[5px] flex items-center absolute left-[-1px] top-[-1px] rounded-full h-[30px] w-[30px]">
            <img src="/images/reward.png" alt="" />
          </div>
          <div className="rounded-full bg-[#eaa222] bg-opacity-20 text-[#eaa222] w-16 pl-6 text-center">
            {formatNumber(userInfo.club.rank)}
          </div>
          <div className="flex-1 flex items-center relative pr-[24px]">
            <div className="flex-1">{formatNumber(userInfo.pool)}</div>
            <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] absolute right-[4px]" />
          </div>
        </WhiteContainer>
      </div>

      <div className="flex px-5 py-2">
        <CleanTab tabs={tabsList} active={active} onSelect={setActive} />
      </div>
      <div className="flex flex-col px-5 overflow-y-auto" ref={scrollRef} >
        {active === 0 && ownList.length ? ownList.map((club, index) => (
          <ClubOwnItem data={club} key={index} backCount={setKeyCount} backData={setManager} />))
          : null}
      </div>
      <div className="flex flex-col px-5 overflow-y-auto" ref={clubScrollRef} >
        {active === 1 && clubList.length ? clubList.map((club, index) => (
          <ClubAllItem data={club} key={index} backCount={setKeyCount} backData={setManager} />)) : null}
      </div>
      <div className="flex flex-col px-5 overflow-y-auto" ref={actScrollRef} >
        {active === 2 && activityList.length ? activityList.map((activity, index) => (
          <Activity data={activity} key={index} />)) : null}
      </div>

      {
        active === 0 && ownList.length ?
          <Button className="my-2 mx-5 py-2 text-base text-white flex justify-center"
            onClick={() => setActive(1)}
            style={{ backgroundImage: 'linear-gradient(to right, #f48351, #c62655)', paddingLeft: 0, paddingRight: 0 }}>
            <strong>LOOKING FOR MORE CLUBS TO JOIN?</strong>
          </Button> : null
      }

      {
        active === 0 && ownList.length === 0 ?
          <Button className="my-2 mx-5 py-2 text-base text-white flex justify-center"
            onClick={buySelf}
            style={{ backgroundImage: 'linear-gradient(to right, #f48351, #c62655)', paddingLeft: 0, paddingRight: 0 }}>
            <strong>CREATE YOUR CLUB</strong>
          </Button> : null
      }

      <Modal ref={ref} title="BECOME THE MANAGER" backVisible={setVisible}>
        <div className="flex flex-col items-center mt-4 mb-2">
          <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2" >
            <img src={manager.owner.avatar} alt="avatar" className="w-16 h-16" />
          </div>
          <div className="text-sm my-2">Buy {keyCount} key to manage club for</div>
          <div className="flex flex-row items-center mb-4 text-lg">
            <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] mr-2' />
            {buyEth} $ETH
          </div>
          <div className="flex flex-row justify-between items-center w-full px-6 my-2 text-sm">
            <div className="text-[#878788]">Total(inc.fees)</div>
            <div className="flex flex-col">
              <div>{totalBuyEth > 1 ? formatNumber(totalBuyEth) : truncateDecimal(totalBuyEth, 5)} $ETH</div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center w-full px-6 my-2 text-sm">
            <div className="text-[#878788] w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
              {userInfo.club.address}</div>
            <WhiteContainer className="relative ml-2 right-[-5px] text-xs px-2">
              <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] absolute top-[4px] left-[4px]' />
              <div className="min-w-16 ml-[24px] overflow-hidden"
                style={{ color: balance < totalBuyEth ? '#ff6203' : '#3fe51c' }}>
                {balance.toFixed(5)} Available</div>
            </WhiteContainer>
          </div>
          <Button
            className="h-10 bg-[#fcb833] text-black flex items-center mt-4 justify-center text-sm "
            onClick={buy} disabled={isBuying || balance < totalBuyEth}
          >
            <strong>BUY NOW</strong>
          </Button>
        </div>
      </Modal>
    </div >
  );
};

export default Mission;



