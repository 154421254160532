import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../components/Modal";
import CountTimer from "../components/CountTimer";
import { post, get } from "../utils/request";
import { formatNumber, truncateDecimal } from "../utils/tools";

const Mission = () => {
    const earnRef = useRef(null)
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.data;
    const [earnings, setEarnings] = useState({
        "club": 0,
        "manager": 0,
        "owner": 0,
        "you": 0,
        total_keys: 0,
        you_keys: 0,
    })
    const [rate, setRate] = useState(1);
    const [totalRate, setTotalRate] = useState(1);

    useEffect(() => {
        getReward()
        getRate()
    }, [])

    useEffect(() => {
        setTotalRate(rate * data.task.quest.rate)
    }, [rate])

    const getReward = async () => {
        let result = await post('/quest/reward', { club_address: data.address, quest_id: data.task.quest.id })
        if (result.code === 1000) {
            setEarnings(result.data)
        }
    }

    const openModal = () => {
        if (earnRef.current) {
            earnRef.current.togglePopup()
        }
    }

    const getRate = async () => {
        let result = await get('/trade/rate')
        let { code, data } = result
        if (code === 1000) {
            setRate(data)
        }
    }

    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-cover bg-no-repeat bg-top"
            style={{ backgroundImage: `url(${data.task.quest.image})` }} >

            <div className="h-14 relative top-4 z-10 w-full flex items-center justify-center">
                <div className="w-[70%] rounded-xl  bg-[#120f0f] border-2 border-[#555] px-2 h-12 text-center
                flex items-center"  style={{ whiteSpace: 'nowrap', overflow: 'auto' }}>
                    <strong>{data.task.quest.title}</strong>
                </div>
            </div>

            <div className="z-10 text-sm mx-4 rounded-lg fixed top-20 p-4 min-h-8 bg-white
            bg-opacity-20 text-[#a59e91] break-all max-w-[740px]" style={{ width: "calc(100% - 2rem)" }}>
                {data.task.quest.describe}
            </div>

            <div className="fixed bottom-0 w-full max-w-[768px] px-6 flex flex-col items-center py-10 text-sm">
                <div className="flex w-full flex-row justify-between mb-3">
                    <div className="flex justify-end flex-[4] ">
                        Mission Ends in:
                    </div>
                    <div className="flex justify-start flex-[3] pl-4">
                        <CountTimer lootDate={data.task.end_date} notice={() => { }}>
                        </CountTimer>
                    </div>
                </div>
                {/* <div className="flex w-full flex-row justify-between mb-3">
                    <div className="flex justify-end flex-[4] ">
                        Accumulated Earnings:
                    </div>
                    <div className="flex justify-start flex-[3] pl-4 items-center">
                        <img src="/images/ttt.png" alt="" className="w-[20px] mr-[2px]" />
                        0
                    </div>
                </div> */}
                <div className="border border-[#a59e9190] w-full my-1"></div>
                <div className="flex w-full flex-row justify-between my-3">
                    <div className="flex justify-end flex-[4] ">
                        Total Club Earnings:
                    </div>
                    <div className="flex justify-start flex-[3] pl-4">
                        <img src="/images/ttt.png" alt="" className="w-[20px] mr-[2px]" />
                        {earnings.manager / 0.4 > 1 ? formatNumber(earnings.manager * totalRate / 0.4) :
                            truncateDecimal((earnings.manager / 0.4) * totalRate, 5)}
                    </div>
                </div>
                <div className="flex w-full flex-row justify-between mb-4">
                    <div className="flex justify-end flex-[4] ">
                        My Earnings:
                    </div>
                    <div className="flex justify-start flex-[3] pl-4">
                        <img src="/images/ttt.png" alt="" className="w-[20px] mr-[2px]" />
                        {earnings.you > 1 ? formatNumber(earnings.you * totalRate) : truncateDecimal(earnings.you * totalRate, 5)}
                        <div className="ml-[4px] border-2 flex items-center justify-center rounded-full h-[20px] w-[20px] border-[#808080]"
                            onClick={openModal}>
                            <strong className="text-[#808080]">?</strong>
                        </div>
                    </div>
                </div>
                {data.task.quest.type === 0 ? <div className="flex w-full flex-row justify-between mb-4">
                    <div className="flex justify-end flex-[4] ">
                        Special Earnings:
                    </div>
                    <div className="flex justify-start flex-[3] pl-4">
                        <img src="/images/energy-yellow.png" alt="" className="w-[14px] mr-[5px]" />
                        50
                        <div className="ml-[4px] flex items-center justify-center rounded-full h-[20px] w-[20px] "
                        >
                        </div>
                    </div>
                </div> : null}

                <div className="h-12  w-full">
                    <button className={`w-full h-full flex text-sm bg-[#2c222c] rounded-full items-center justify-center`}
                        onClick={() => navigate(-1)}>
                        <strong>CLOSE</strong>
                    </button>
                </div>

            </div>


            <Modal ref={earnRef} closeIcon={false} title="EARNINGS DETAIL">
                <div className="flex flex-col items-center my-4 w-full text-sm">
                    <div className="w-full text-left break-all">The total club earnings will split 40% to the manager,
                        40% among all members and 20% to the club owner.</div>
                    <div className="flex flex-col w-full my-1">
                        <div className="flex justify-between w-full my-1">
                            <div>Total Club Earnings</div>
                            <div className="flex items-center">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.manager / 0.4 > 1 ? formatNumber(earnings.manager / 0.4 * totalRate) :
                                    truncateDecimal(earnings.manager / 0.4 * totalRate, 5)}
                            </div>
                        </div>
                        <div className="flex justify-between w-full my-1">
                            <div className="flex flex-[2]">Manager's Share</div>
                            <div className="flex flex-1 justify-center items-center">40%</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.manager > 1 ? formatNumber(earnings.manager * totalRate) :
                                    truncateDecimal(earnings.manager * totalRate, 5)}
                            </div>
                        </div>
                        <div className="flex justify-between w-full my-1">
                            <div className="flex flex-[2]">Club Owner's Shares</div>
                            <div className="flex flex-1 justify-center items-center">20%</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.owner > 1 ? formatNumber(earnings.owner * totalRate) :
                                    truncateDecimal(earnings.owner * totalRate, 5)}
                            </div>
                        </div>
                        <div className="flex justify-between w-full my-1 items-center">
                            <div className="flex flex-col flex-[2]">
                                <div>Key Holders Share</div>
                                <div>You Hold {earnings.you_keys}/{earnings.total_keys} Keys</div>
                            </div>
                            <div className="flex flex-1 justify-center items-center">
                                {earnings.total_keys === 0 ? 0 : formatNumber(40 * (earnings.you_keys / earnings.total_keys))}%</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.total_keys === 0 ? 0 : earnings.manager * (earnings.you_keys / earnings.total_keys) > 1 ?
                                    formatNumber(earnings.manager * (earnings.you_keys / earnings.total_keys) * totalRate) :
                                    truncateDecimal(earnings.manager * (earnings.you_keys / earnings.total_keys) * totalRate, 5)}
                            </div>
                        </div>
                        <div className="border-[#ccc] border-t mt-2"></div>

                        <div className="flex justify-between w-full my-2">
                            <div className="flex flex-[2]">My Earnings</div>
                            <div className="flex flex-1 justify-center">
                                {earnings.you === 0 ? "-" : formatNumber(earnings.you / (earnings.manager / 0.4) * 100) + '%'}</div>
                            <div className="flex items-center flex-1 justify-end">
                                <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-1" />
                                {earnings.you > 1 ? formatNumber(earnings.you * totalRate) : truncateDecimal(earnings.you * totalRate, 5)}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >


        </div >
    );
};

export default Mission;
