import React from "react";
// start #ec3468
// end #410483
const Button = (props) => {
  return (
    <button
      {...props}
      className={`px-8 bg-[#fcb833] text-center text-black rounded-full ${props.className ? props.className : ""}`}
    //bg-button-primary
    >
      {props.children}
    </button>
  );
};

export default Button;
