import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";

const LootShare = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-cover bg-no-repeat"
            style={{ backgroundImage: "url(/images/lootshare.png)" }} >

            <div className="fixed top-6 left-0 text-white w-full text-lg">
                <div className="relative flex items-center justify-center">
                    <strong>LOOT SUCCESSFUL</strong>
                    {/* <div className="absolute left-[10px]" onClick={() => navigate(-1)}>
                        <img className="w-2" src="/images/back.png" alt="back" />
                    </div> */}
                </div>
            </div>

            <div className="fixed bottom-6 w-full max-w-[768px]">
                <div className="p-4 text-sm text-[#929194] m-2 flex justify-center">
                    Share with your club on X for bonus
                    <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mx-1" />
                    185
                </div>
                <div className="bg-[#212025] p-4 text-sm text-[#929194] rounded-xl mx-4">
                    The target system's security measures exceed your expectations,
                    The target system's security measures exceed your expectations,
                </div>

                <div className="h-12 my-3 px-4">
                    <Button className={`w-full h-full flex flex-col text-sm items-center justify-center`}
                        onClick={() => { navigate('/main') }} >
                        <div className="flex flex-1 flex-row items-center">
                            <strong>POST TO X</strong>
                        </div>
                        <div className="flex flex-1 flex-row items-center">
                            <strong>GET</strong>
                            <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mx-1" />
                            <strong>123123 $JOMP BONUS</strong>
                        </div>
                    </Button>
                </div>

                <div className="px-4">
                    <div className="my-3 h-12 rounded-full px-4 flex flex-col items-center justify-center text-sm"
                        style={{ background: "#2c222c" }}
                        onClick={() => { navigate(-1) }}>
                        <strong>I DON'T WANT A BONUS</strong>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default LootShare;
