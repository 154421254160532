import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [timeToNextHour, setTimeToNextHour] = useState(calculateTimeToNextHour());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
            setTimeToNextHour(calculateTimeToNextHour());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function calculateTimeToNextHour() {
        const now = new Date();
        const nextHour = new Date(now);
        nextHour.setHours(now.getHours() + 1, 0, 0, 0);
        return Math.floor((nextHour - now) / 1000);
    }

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <div className='ml-1'>
            {formatTime(timeToNextHour)}
        </div>
    );
};

export default CountdownTimer;
