import { REACT_APP_REST_API } from "../config"

// const host = process.env.REACT_APP_REST_API
const host = REACT_APP_REST_API

export const bindAccount = async (url, data) => {
    let realUrl = host + url
    const response = await fetch(realUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    return response.json();
}


export const get = async (url) => {
    let realUrl = host + url
    const response = await fetch(realUrl, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    });
    // return response.json();
    if (response.status === 402) {
        localStorage.clear()
        window.location.href = '/'
        return
    } else if (response.status === 200) {
        return response.json();
    }
}

export const post = async (url, data) => {
    let realUrl = host + url
    const response = await fetch(realUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    if (response.status === 402) {
        localStorage.clear()
        window.location.href = '/'
        return
    } else if (response.status === 200) {
        return response.json();
    }
}

export const put = async (url, data) => {
    let realUrl = host + url
    const response = await fetch(realUrl, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    if (response.status === 402) {
        localStorage.clear()
        window.location.href = '/'
        return
    } else if (response.status === 200) {
        return response.json();
    }
}

export const del = async (url) => {
    let realUrl = host + url
    const response = await fetch(realUrl, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": localStorage.getItem("token")
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    });
    if (response.status === 402) {
        localStorage.clear()
        window.location.href = '/'
        return
    } else if (response.status === 200) {
        return response.json();
    }
}