import React, { useState, useRef, useEffect } from "react";
import ClubRoom from "../../components/ClubRoom";
import Tab from "../../components/Tab";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import WishItem from "../../components/WishItem";
import { useNavigate } from "react-router-dom";
import { list, wishList } from "../../utils/mockData";
import { get } from "../../utils/request";
import { ToastContainer, toast } from "react-toastify";

const tabList = [
  {
    name: "Trend",
  },
  // {
  //   name: "Live",
  // },
  {
    name: "Upcoming",
  },
  {
    name: "Top",
  },
  // {
  //   name: "Wish",
  // },
];

const Explore = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const markRef = useRef(null);
  const searchRef = useRef(null);
  const scrollRef = useRef(null);
  const [clubList, setClubList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(20);
  const [keyword, setKeyword] = useState("");
  const [isScrolledToNewBottom, setIsScrolledToNewBottom] = useState(false);

  useEffect(() => {
    getData();
  }, [page, keyword,active]);

  useEffect(() => {
    const scrollableDiv = scrollRef.current;
    const handleScroll = () => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;

      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (!isScrolledToNewBottom) {
          setPage((prePage) => prePage + 1);
          console.log("滚动到了底部");
          setIsScrolledToNewBottom(true);
        }
      } else {
        setIsScrolledToNewBottom(false);
      }
    };
    scrollableDiv.addEventListener("scroll", handleScroll);
    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getData = async () => {
    if (page === 1) {
      let result = await get(
        `/club/list?page=${page}&size=${size}&filter=${keyword}&sort=${tabList[active].name}`
      );
      let { code, data } = result;
      if (code === 1000) {
        let { total, list } = data;
        setClubList(list);
        setTotal(total);
      }
    } else if ((page - 1) * size < total) {
      let result = await get(
        `/club/list?page=${page}&size=${size}&filter=${keyword}&sort=${tabList[active].name}`
      );
      let { code, data } = result;
      if (code === 1000) {
        let { total, list } = data;
        let tempList = [...clubList];
        let currentList = tempList.concat([...list]);
        setClubList(currentList);
        setTotal(total);
      }
    }
  };

  const handleClick = () => {
    if (markRef.current) {
      markRef.current.togglePopup();
    }
  };

  const onSearch = () => {
    let value = searchRef.current.value;
    if (value.length === 0) {
      toast.warning("please input the keyword!");
    }
    setKeyword(value);
  };
  const onReset = () => {
    setKeyword("");
    searchRef.current.value = "";
  };
  return (
    <div
      className="h-full flex flex-col p-4 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage:
          active !== 4 ? "url(/images/explore.png)" : "url(/images/wish.png)",
      }}
    >
      <ToastContainer pauseOnFocusLoss={false} theme="dark" position="top-center" />
      <div className="bg-black bg-opacity-50 h-10 rounded-full flex relative flex-none">
        <input
          type="text"
          placeholder="Please input the keyword!"
          className="flex-1 px-4 bg-transparent rounded-full"
          style={{ border: "2px solid #3d2d41" }}
          ref={searchRef}
        />
        <button
          className={`absolute ${keyword == "" ? "right-4" : "right-14"} top-2`}
          onClick={onSearch}
        >
          <img className="h-5" src="/images/search.png" alt="search" />
        </button>
        {keyword !== "" && (
          <button className="absolute right-4 top-2" onClick={onReset}>
            <img className="h-5" src="/images/cancel.png" alt="search" />
          </button>
        )}
      </div>
      <div className="flex items-center py-2">
        <div className="flex-1 pl-1">
          <Tab
            tabs={tabList}
            active={active}
            onSelect={setActive}
          />
        </div>
        <div className="pl-2">
          <Button
            className="h-8 flex items-center justify-center w-20"
            onClick={() => {
              navigate("/main", { state: { active: 1 } });
            }}
          >
            <strong>SWEEP</strong>
          </Button>
        </div>
      </div>

      {active === 4 ? (
        <div className="w-full flex flex-row items-center">
          <Button className="flex-1 h-8 text-sm items-center mb-1">
            <strong>CREATE A WISH</strong>
          </Button>
          <div
            className="ml-3 border-2 flex items-center justify-center rounded-full h-[20px] w-[20px] border-[#808182]"
            onClick={handleClick}
          >
            <strong className="text-[#808182]">?</strong>
          </div>
        </div>
      ) : null}
      {active !== 4 ? (
        <div
          className="flex flex-wrap justify-left overflow-y-auto"
          ref={scrollRef}
        >
          {clubList.map((club, index) => (
            <ClubRoom key={index} club={club} />
          ))}
        </div>
      ) : null}

      {active === 5 ? (
        <div className="flex flex-wrap justify-left overflow-y-auto">
          <div className="w-full">
            <div className="mt-3">
              {wishList.map((wish, index) => (
                <WishItem data={wish} key={index} />
              ))}
            </div>
          </div>
        </div>
      ) : null}

      <Modal
        ref={markRef}
        title="INTRODUCTION TO WISHES"
        type="center"
        closeIcon={false}
      >
        <div className="flex flex-col mt-4 flex-1 text-sm relative text-gray-300">
          <p className="mb-4">
            Onboard your favorite creator on JUMPUP by using the Wish feature.
            Simply input their handle, create a wish, and buy the first key to
            activate them in the game.
          </p>
          <div className="text-base text-left font-bold">
            Wishes Royalties and Incentives
          </div>
          <p>
            When Wishes are traded, 5% of the total trade volume is claimable as
            royalty fees by the creator upon their registration on JUMPUP. The
            first 20 keys are reserved for the wished creator which they can
            claim after they register on JUMPUP.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Explore;
