import React from "react";
import { usePrivy } from "@privy-io/react-auth";
import Button from "../components/Button";
import { Navigate } from "react-router-dom";

const Index = () => {
  const { ready, authenticated, login } = usePrivy();

  if (!ready) {
    return null;
  } else {
    return ready && authenticated ? (
      <Navigate to="/main" replace={true} />
    ) : (
      <div className="flex w-full h-full items-center justify-center max-w-[768px]">
        <Button className="w-48" onClick={login}>
          Sign In
        </Button>
      </div>
    );
  }
};

export default Index;
