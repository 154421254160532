export const timeAgo = (date) => {
    let newDate = date
    try {
        newDate = new Date(date.toString())
    } catch (error) {

    }
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - newDate.getTime()) / 1000);
    const mins = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30); // 简化计算，每月按30天算
    const years = Math.floor(days / 365); // 每年按365天算

    if (years > 0) return `${years} years ago`;
    if (months > 0) return `${months} months ago`;
    if (days > 0) return `${days} days ago`;
    if (hours > 0) return `${hours} hours ago`;
    if (mins > 0) return `${mins} minutes ago`;
    return `just now`; // 如果是1分钟内
};

export const getEthToUsd = async () => {
    try {
        const response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const res = await response.json();
        let usd = res.ethereum.usd
        return usd
    } catch (error) {
        return 2220
    }
}

export function formatNumber(num) {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'b';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num.toFixed(2).replace(/\.00$/, '');
}

export function truncateDecimal(number, precision) {
    if (number === 0) {
        return 0
    }
    const factor = 10 ** precision;
    const truncatedNumber = Math.trunc(number * factor) / factor;
    return truncatedNumber;
}