import React from "react";

const Loading = (props) => {
  return (
    <div
      {...props}
      className={`absolute ${!props.show ? "hidden" : ""
        } left-0 top-0 w-full h-full z-10 flex align-middle justify-center placeholder:justify-center 
        bg-black
      ${props.className ? props.className : ""}`}
    >
      {/* bg-gray-900 bg-opacity-50  */}
      <div className="loading-circle"></div>
      <div className="step">
        <span style={{ "--i": 1 }}>L</span>
        <span style={{ "--i": 2 }}>o</span>
        <span style={{ "--i": 3 }}>a</span>
        <span style={{ "--i": 4 }}>d</span>
        <span style={{ "--i": 5 }}>i</span>
        <span style={{ "--i": 6 }}>n</span>
        <span style={{ "--i": 7 }}>g</span>
      </div>
    </div>
  );
};

export default Loading;
