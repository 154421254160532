import React, { useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../components/Modal"
import { formatNumber } from '../utils/tools'



const LootFail = () => {
    const manRef = useRef(null)
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from;
    const to = location.state?.to;

    const openManModal = () => {
        if (manRef.current) {
            manRef.current.togglePopup()
        }
    }

    useEffect(() => {
        window.history.pushState(null, '', document.URL);
        window.onpopstate = function () {
            window.history.pushState(null, '', document.URL);
        };
        return () => {
            // 回退事件只作用于当前组件，则需要在离开页面、组件销毁时把回退事件销毁
            window.onpopstate = null;
        };
    }, []);


    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-cover bg-no-repeat bg-center"
            style={{ backgroundImage: "url(/images/lootfail.png)" }} >

            <div className="fixed top-6 left-0 text-white w-full text-lg">
                <div className="relative flex items-center justify-center">
                    <strong>LOOT FAILED</strong>
                    {/* <div className="absolute left-[10px]" onClick={() => navigate(-1)}>
                    <img className="w-2" src="/images/back.png" alt="back" />
                </div> */}
                </div>
            </div>

            <div className="fixed bottom-20 w-full max-w-[768px]">
                <div className="w-full flex justify-around px-4 mb-8">
                    <div className="flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
                            <img src={from.owner.avatar ? from.owner.avatar : "/images/avatar.png"} alt="avatar" />
                        </div>
                        <div className="w-20 overflow-hidden overflow-ellipsis whitespace-nowrap text-xs mt-1 text-center">
                            {from.owner.name}
                        </div>
                        <div className="text-[12px] text-[#808081] my-1">Power</div>
                        <div className="flex flex-row items-center">
                            <strong className="text-[#ffc000] text-lg text-center max-w-24 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {formatNumber(from.power)}</strong>
                            <div className="ml-2 border-2 flex items-center justify-center rounded-full h-[20px] w-[20px] border-[#808080]"
                                onClick={openManModal}>
                                <strong className="text-[#808080]">?</strong>
                            </div>
                        </div>
                    </div>
                    <img src="/images/vs.png" alt="" className="h-24 w-24" />
                    <div className="flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
                            <img src={to.owner.avatar ? to.owner.avatar : "/images/avatar.png"} alt="avatar" />
                        </div>
                        <div className="w-20 overflow-hidden overflow-ellipsis whitespace-nowrap text-xs mt-1 text-center">
                            {to.owner.name} </div>
                        <div className="text-[12px] text-[#808081] my-1">Power</div>
                        <div className="flex flex-row items-center">
                            <strong className="text-[#ffc000] text-lg text-center max-w-24 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {formatNumber(to.power)}</strong>
                            <div className="ml-2 border-2 flex items-center justify-center rounded-full h-[20px] w-[20px] border-[#808080]"
                                onClick={openManModal}>
                                <strong className="text-[#808080]">?</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#929194] p-4 text-sm text-[#929194] rounded-xl bg-opacity-20 mx-4">
                    The target system's security measures exceed your expectations, and your hacking attempt is
                    Successfully countered,resulting in the future of your plan.
                </div>

                <div className="px-4 relative">
                    {/* <div className="my-3 h-12 rounded-xl px-4 flex flex-col" style={{ background: "#2b1d2c" }}>
                        <div className="flex flex-1 justify-between items-center text-sm">
                            <div style={{ color: "#808080" }}>You got loot fee refund</div>
                            <div className="flex flex-row">
                                <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-1" />
                                185
                            </div>
                        </div>
                    </div> */}

                    <div className="mt-4 text-base text-center mb-3">
                        <strong>You failed to loot the club!</strong>
                    </div>

                    <div className="h-12 mb-3">
                        <button className={`w-full h-full flex text-sm bg-[#2c222c] rounded-full items-center justify-center`}
                            onClick={() => navigate('/main/battle')}>
                            <strong>CLOSE</strong>
                        </button>
                    </div>
                </div>
            </div>

            <Modal ref={manRef} closeIcon={false}>
                <div className="flex flex-col items-center my-4 w-full text-sm">
                    <div className="mb-2">Power is determined by the total value of all Members and Joined.</div>
                    <div className="mb-1 text-[#fcb833]"> X = Total value of all Members</div>
                    <div className="mb-1 text-[#fcb833]"> Y = Total value of all Joined</div>
                    <div className="mb-1 text-[#fcb833]"> (For Wish accounts, Y = current Key Price)</div >
                    <div className="text-center mb-1 text-[#fcb833]"> N = Number of Club Keys are generated and bought</div >
                    <div className="mb-1 text-[#fcb833]"> T = min(X * min(N/50,1),Y*10)+ Y</div>
                    <div className="mb-1">Club value(T)</div>
                    <div className="mb-1 text-[#fcb833]">Power = Int(SQRT(50*T)*7))</div>
                </div >
            </Modal>

        </div >
    );
};

export default LootFail;
