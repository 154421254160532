import React from "react";
import { timeAgo } from ".././utils/tools"
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/tools"


const Activity = ({ data }) => {
    const navigate = useNavigate()

    const linkToUser = (id) => {
        navigate('/club/' + id)
    }

    return (
        <div className="bg-[#a6a1bb] flex bg-opacity-20 mb-3 py-0.85 rounded-lg">
            {/* avatar */}
            <div className="ml-4 mr-2 flex items-center justify-center w-20">
                <div className="w-11 h-11 flex flex-none items-center justify-center rounded-full 
                border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2"
                    onClick={() => linkToUser(data.from_club_address)}>
                    <img src={data.from_club_avatar ? data.from_club_avatar : '/images/avatar.png'} alt="" className="w-11" />
                </div>
                <div className="w-11 h-11 flex flex-none items-center justify-center rounded-full border-white 
                overflow-hidden bg-black bg-opacity-50 border-solid border-2 relative right-3"
                    onClick={() => linkToUser(data.to_club_address)}>
                    <img src={data.to_club_avatar ? data.to_club_avatar : '/images/avatar.png'} alt="" className="w-11" />
                </div>
            </div>
            {/* behavior flex flex-1 flex-col justify-between */}
            <div className="py-1 pr-2">
                {data.type === 1 || data.type === 2 ?
                    <div className="text-sm break-all">
                        <span className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                            <strong>{data.from_club_name}</strong> </span>
                        <span className="text-[0.9rem]">
                            {data.type === 1 ? " bought" : " sold"}</span>
                        {" " + data.cost}
                        <span className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                            <strong>{" " + data.to_club_name}</strong>
                        </span>
                        <span className="text-[0.9rem]"> key</span>
                    </div>
                    : null}

                {data.type === 6 ? <div className="text-sm break-all">
                    <span className="text-[0.9rem]">The club</span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.from_club_name}</strong>
                    <span className="text-[0.9rem]"> looted the club</span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.to_club_name}
                    </strong>
                </div> : null}

                {data.type === 7 ? <div className="text-sm break-all">
                    <span className="text-[0.9rem] ">The club </span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {data.to_club_name}</strong>
                    <span className="text-[0.9rem] "> has been looted by</span>
                    {/* <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.to_club_name}</strong> */}
                    <span className="text-[0.9rem] "> the club</span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.from_club_name}
                    </strong>
                </div> : null}

                {data.type === 3 ?
                    <div className="text-sm break-all">
                        <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                            {data.from_club_name}</strong>
                        <span className="text-[0.9rem]"> received mission from Club</span>
                        <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                            {" " + data.to_club_name}
                        </strong>
                    </div>
                    : null}

                {data.type === 4 ? <div className="text-sm break-all">
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {data.from_club_name}</strong>
                    <span className="text-[0.9rem]"> has finished the mission from Club</span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.to_club_name}
                    </strong>
                </div> : null}

                {data.type === 11 ? <div className="text-sm break-all">
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {data.from_club_name}</strong>
                    <span className="text-[0.9rem]"> has finished the mission from Club</span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.to_club_name}
                    </strong>
                </div> : null}

                {data.type === 5 ? <div className="text-sm break-all">
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {data.from_club_name}</strong>
                    <span className="text-[0.9rem]"> has unlocked the mission from Club</span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.to_club_name}
                    </strong>
                </div> : null}

                {data.type === 9 ? <div className="text-sm break-all">
                    Club
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.from_club_name}</strong>
                    <span className="text-[0.9rem]"> has boosted $TTT</span>
                </div> : null}

                {data.type === 10 ? <div className="text-sm break-all">
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {data.from_club_name}</strong>
                    <span className="text-[0.9rem]"> has getted rewards for Club</span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {" " + data.to_club_name}
                    </strong>
                </div> : null}

                {data.type === 8 ? <div className="text-sm break-all">
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap text-[0.9rem]">
                        {data.from_club_name}</strong>
                    <span className="text-[0.9rem]"> now leads Club</span>
                    <strong className="max-w-14 overflow-hidden overflow-ellipsis whitespace-nowrap ml-1 text-[0.9rem]">
                        {" " + data.to_club_name}
                    </strong>
                </div> : null}

                {/* time */}
                <div className="text-sm text-[#a8a5a9]" style={{ display: 'inline-block' }}>
                    {data.type === 4 ? <div style={{ display: 'inline-block' }}>
                        <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-[2px] mb-[3px]"
                            style={{ display: 'inline-block' }} />
                        <strong className="text-white text-[1.025rem]">{formatNumber(data.cost)}</strong> profit sharing earned  {data.remark},
                        {timeAgo(data.create_date)}
                    </div> : null}
                    {data.type === 6 ? <div style={{ display: 'inline-block' }}>
                        <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-[2px] mb-[3px]"
                            style={{ display: 'inline-block' }} />
                        <strong className="text-white text-sm">{formatNumber(data.cost)}</strong> profit sharing earned {data.remark.split(',')[0]},
                        {timeAgo(data.create_date)}
                    </div> : null}
                    {data.type === 7 ? <div style={{ display: 'inline-block' }}>
                        <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-[2px] mb-[3px]"
                            style={{ display: 'inline-block' }} />
                        <strong className="text-white text-sm">{formatNumber(data.cost)}</strong> were lost,
                        {timeAgo(data.create_date)}
                    </div> : null}
                    {data.type === 9 ? <div style={{ display: 'inline-block' }}>
                        <span> The pool has accumulated to</span>
                        <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mx-[4px] mb-[3px]" style={{ display: 'inline-block' }} />
                        <strong className="text-white text-sm">{formatNumber(data.cost)}</strong>
                        ,{timeAgo(data.create_date)}
                    </div> : null}
                    {data.type === 10 ? <div style={{ display: 'inline-block' }}>
                        <img src="/images/ttt.png" alt="" className="w-[14px] h-[14px] mr-[2px] mb-[3px]"
                            style={{ display: 'inline-block' }} />
                        <strong className="text-white text-sm">{formatNumber(data.cost)}</strong> profit sharing earned,
                        {timeAgo(data.create_date)}
                    </div> : null}
                    {data.type === 11 ? <div style={{ display: 'inline-block' }}>
                        <img src="/images/energy-orange.png" alt="" className="w-[14px] h-[14px] mr-[2px] mb-[3px]"
                            style={{ display: 'inline-block' }} />
                        <strong className="text-white text-sm">{formatNumber(data.cost)}</strong> energy earned,
                        {timeAgo(data.create_date)}
                    </div> : null}
                    {data.type === 1 || data.type === 2 || data.type === 3 || data.type === 5
                        || data.type === 8 ? timeAgo(data.create_date) : null}
                </div>
            </div>
        </div >
    );
};
export default Activity;
