import React, { useState } from "react";
import Button from "./Button";
import DataContainer from "./DataContainer";
import CountTimer from "./CountTimer";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/tools";

const Club = ({ data, index }) => {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false)

  const showRank = (rank) => {
    switch (rank) {
      case 1:
        return [
          <span key="1">1</span>,
          <span className="text-xs pb-2" key="st">
            st
          </span>,
        ];
      case 2:
        return [
          <span key="2">2</span>,
          <span className="text-xs pb-2" key="nd">
            nd
          </span>,
        ];
      case 3:
        return [
          <span key="3">3</span>,
          <span className="text-xs pb-2" key="rd">
            rd
          </span>,
        ];
      default:
        return rank < 1000 ? ("#" + rank) :
          [
            <span key="3">1000</span>,
            <span className="text-xs pb-2" key="rd">
              +
            </span>,
          ];
    }
  };

  return (
    <div className="bg-[#a6a1bb] flex bg-opacity-20 mb-4 py-1 rounded-lg">
      <div className="px-4 flex flex-col relative top-[5px]">
        <div className="w-14 h-14 flex items-center justify-center rounded-full border-white 
        overflow-hidden bg-black bg-opacity-50 border-solid border-2"
          onClick={() => { navigate('/club/' + data.owner.id) }}>
          <img src={data.owner.avatar} alt="avatar" className="w-14" />
        </div>
        <div className="relative bg-white top-[-10px] z-1 h-4 font-sm m-auto w-[80%] text-black rounded-full">
          <div className="flex h-4 items-center justify-center">
            {showRank(index + 1)}
          </div>
        </div>
      </div>
      <div className="flex-1 py-3.5 flex flex-col">
        <div className="flex-1 text-sm flex">
          <div className="max-w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
            <strong className="text-sm">{data.owner.name}</strong>
          </div>
          <div
            className="h-[18px] w-[18px] flex items-center justify-center text-xs mx-1"
            style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
          >
            <strong>{data.level}</strong>
          </div>
          {data.isBinded ? (
            <div
              style={{
                background: "url(/images/twitter.png) no-repeat center center",
                backgroundSize: "100%",
              }}
              className="p-1 mx-1 text-xs flex items-center rounded-full m-[1px] h-[16px] w-[16px] justify-center"
            ></div>
          ) : null}
        </div>
        {/* <div className="py-1 flex">
          <WhiteContainer className="flex relative items-center w-36">
            <img src="/images/ttt.png" alt="" className='h-[20px] w-[20px] ml-1' />
            <div className="text-gray-400 text-left pl-2 flex-1">Pool</div>
            <div className="pr-2">
              {formatNumber(data.pool)}
            </div>
          </WhiteContainer>
        </div> */}
        <div className="flex-1 flex-none">
          <DataContainer className="flex relative items-center w-32">
            <img src="/images/ttt.png" alt="" className='h-[22px] w-[22px] relative left-[-2px]' />
            <div className="text-left flex-1 flex items-center">Pool</div>
            <div className="pr-1">
              {formatNumber(data.pool)}
            </div>
          </DataContainer>
        </div>

      </div>
      <div className="min-w-28 pr-4 flex items-center justify-center">
        {data.can_looted === "Other" ?
          (new Date(data.loot_date) - new Date() > 0 && !flag ?
            <Button className="h-8 w-24 flex items-center justify-center" disabled >
              <strong><CountTimer lootDate={data.loot_date} notice={setFlag}></CountTimer></strong>
            </Button>
            : <Button className="h-8 w-24 flex items-center justify-center"
              disabled={data.pool === 0}
              onClick={() => { navigate('/choose', { state: { data } }) }}  >
              <strong>LOOT</strong>
            </Button>) :
          <Button className="h-8 w-24 flex items-center justify-center text-white" disabled >
            <strong>{data.can_looted}</strong>
          </Button>
        }
      </div>
    </div >
  );
};
export default Club;


// new Date(data.loot_date) - new Date() > 0 ?
//             <Button className="h-8 w-24 flex items-center justify-center" disabled>
//               <strong><CountTimer lootDate={data.loot_date} notice={refresh}></CountTimer></strong>
//             </Button> :
//             <Button className="h-8 w-24 flex items-center justify-center"
//               disabled={data.pool === 0}
//               onClick={() => { navigate('/choose', { state: { data } }) }}  >
//               <strong>LOOT</strong>
//             </Button>