import React from 'react';
import ReactDOM from 'react-dom/client';
import "agora-chat-uikit/style.css";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
import { REACT_APP_PRIVY_APP_ID } from '../src/config';
import reportWebVitals from './reportWebVitals';
import { PrivyProvider } from '@privy-io/react-auth';
import { BrowserRouter } from "react-router-dom";
import { bindAccount } from './utils/request'
import { UIKitProvider } from 'agora-chat-uikit';
import { arbitrum, goerli, arbitrumGoerli, } from '@wagmi/chains';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <UIKitProvider initConfig={{
      appKey: "411088836#1269582",
    }}>
      <PrivyProvider
        appId={REACT_APP_PRIVY_APP_ID}
        onSuccess={async (user) => {
          let { id, linkedAccounts } = user
          const url = window.location.href;
          const urlObject = new URL(url);
          const queryParams = new URLSearchParams(urlObject.search);
          const invite = queryParams.get('invite') || "";
          let userResult = await bindAccount("/user/bind", { id, linked_accounts: linkedAccounts, invite_code: invite })
          let { code, data } = userResult
          if (code === 1000) {
            localStorage.setItem("userInfo", JSON.stringify(data.userInfo))
            localStorage.setItem("token", data.token)
          } else {
            localStorage.setItem("fail", "true")
          }
        }}
        config={{
          loginMethods: ['email', 'google', 'apple'],
          appearance: {
            theme: 'dark',
            accentColor: '#676FFF',
            logo: '/auth.png',
          },
          // defaultChain: arbitrumGoerli,
          defaultChain: goerli,
          embeddedWallets: {
            createOnLogin: 'all-users'
          }
        }}
      >
        <App />
      </PrivyProvider>
    </UIKitProvider>
  </BrowserRouter >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
