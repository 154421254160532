import React, { useState, useEffect } from "react";
import QuestGallery from "../components/QuestGallery";
import { useLocation } from 'react-router-dom';
import { get } from "../utils/request"


const Quest = () => {
  const location = useLocation();
  const address = location.state?.address;
  const level = location.state?.level;
  const avatar = location.state?.avatar;
  const name = location.state?.name;
  const energy = location.state?.energy;
  const [quests, setQuests] = useState([])

  useEffect(() => {
    if (address) {
      getData()
    }
  }, [])

  const getData = async () => {
    let result = await get(`/quest/list/${address}`)
    let { code, data } = result
    if (code === 1000) {
      setQuests(data)
    }
  }

  const getStatus = (index) => {
    let tempQuests = [...quests]
    tempQuests[index].unlock_status = true
    setQuests(tempQuests)
  }

  return (
    <div className="w-full flex flex-col h-full max-w-[768px]">
      <QuestGallery quests={quests} energy={energy} address={address} level={level}
        name={name} avatar={avatar} changeStatus={getStatus} />
    </div>
  );
};

export default Quest;


