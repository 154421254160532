import React, { useEffect, useRef, userRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button"
import { ToastContainer, toast } from 'react-toastify';
import { get } from "../utils/request"

const Invite = () => {
    const navigate = useNavigate()
    const [code, setCode] = useState('')
    const [userInfo, setUserInfo] = useState({ invite_user: { id: '' } })

    useEffect(() => {
        let preUserInfo = JSON.parse(localStorage.getItem("userInfo"))
        if (preUserInfo) {
            setUserInfo(preUserInfo)
        } else {
            navigate('/', { replace: true })
        }
    }, [])

    const handleClick = async () => {
        if (!code) {
            toast.warn('please input referral code!')
        } else {
            let res = await get('/user/invite/' + code)
            console.log(res)
            if (res.code === 1000) {
                userInfo.invite_user = { id: res.data }
                localStorage.setItem("userInfo", JSON.stringify(userInfo))
                setTimeout(() => {
                    navigate('/start')
                }, 1000);
            } else {
                toast.warn(res.msg)
            }
        }
    }

    return (
        <div className="w-full flex flex-col h-full max-w-[768px] overflow-hidden bg-cover bg-no-repeat 
        bg-center items-center px-5 justify-center"
            style={{ backgroundImage: "url(/images/invite.png)" }}>
            <ToastContainer theme="dark" position="top-center" />
            <div className="flex flex-col w-full justify-center items-center">
                <img src="/logo.jpg" alt="" className="w-40 rounded-2xl mb-10" />
                <div className="flex bg-black bg-opacity-60 w-full rounded-2xl p-8 justify-center flex-col">
                    <div className="text-lg text-center"><strong>Get a Referral Code to Start</strong></div>
                    <div className="my-4 flex items-center justify-center">
                        <strong>
                            <input type="text" className="bg-white h-12 w-full rounded-full bg-opacity-70 
                                    text-black px-5 init-input text-center text-lg"
                                placeholder="Enter Referral Code"
                                onChange={(e) =>
                                    setCode(e.currentTarget.value)
                                } />
                        </strong>
                    </div>
                    <Button className='w-full h-12 text-white'
                        style={{
                            backgroundImage: 'linear-gradient(to right, #f48351, #c62655)',
                            paddingLeft: 0, paddingRight: 0,
                            cursor: code ? 'auto' : 'not-allowed'
                        }}
                        onClick={handleClick}>
                        <strong>PROCEED</strong>
                    </Button>
                </div>
            </div>

        </div>
    )
}


export default Invite;