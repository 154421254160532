import React from "react";

const Tab = ({ className, tabs, active, onSelect }) => {
  return (
    <div
      className={`h-10 max-w-80 items-center bg-transparent flex-1 flex text-white 
      rounded-full ${className ? className : ""}`} >
      {tabs.map((tab, index) => (
        <div
          key={tab.name}
          onClick={() => onSelect(index)}
          style={{
            color: index === active ? "white" : "#88818a",
          }}
          className={`h-full cursor-pointer flex items-center justify-start mr-4 rounded-full`}//7910a1
        >
          {tab.name}
        </div>
      ))}
    </div>
  );
};

export default Tab;
