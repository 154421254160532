import React, { useEffect, useState } from 'react';

const EditableDiv = ({ data, backValue, children }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {
        setValue(data)
    }, [data])

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleChange = (event) => {
        let currentValue = event.target.value
        setValue(parseInt(currentValue))
        backValue(parseInt(currentValue))
    };

    return (
        <div onClick={handleClick} onBlur={handleBlur} className='w-full text-center h-full'>
            {isEditing ? (
                <input
                    className='w-full text-center h-full bg-transparent'
                    type="number"
                    value={value}
                    onChange={handleChange}
                    autoFocus
                    onBlur={handleBlur}
                />
            ) : (
                <div className='flex flex-row justify-center'>
                    {/* <img className="h-5" src="/images/key-white.png" alt="key" />
                    <div className="pl-2">x {!isNaN(value) ? value : ''}</div> */}
                    {children}
                </div>
            )}
        </div>
    );
};

export default EditableDiv;
