import React, { useRef, useState, useEffect } from "react";
import WhiteContainer from "../../components/WhiteContainer";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import ClubBattle from "../../components/ClubBattle";
import CountdownTimer from "../../components/CountdownTimer";
import { useWallets } from "@privy-io/react-auth";
import { ethers } from "ethers";
import { formatNumber } from "../../utils/tools"
import { useNavigate } from "react-router-dom";
import { get } from "../../utils/request"

const Battle = () => {
  const navigate = useNavigate();
  const cRef = useRef(null);
  const markRef = useRef(null);
  const scrollRef = useRef(null)
  const [clubList, setClubList] = useState([])
  const [userInfo, setUserInfo] = useState({
    avatar: "/images/avatar.png",
    pool: 0,
    club: {
      "rank": 0,
      "level": 1,
      "value": 0,
      "up": 0,
    }
  });
  const [balance, setBalance] = useState(0)
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [reward, setReward] = useState(1);
  const [isScrolledToNewBottom, setIsScrolledToNewBottom] = useState(false);
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );

  useEffect(() => {
    getRankComing()
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo !== null) {
      getUserData(userInfo.id)
    }
  }, [])

  useEffect(() => {
    getBalance()
  }, [embeddedWallet])

  useEffect(() => {
    getData()
  }, [page])

  useEffect(() => {
    const scrollableDiv = scrollRef.current;
    const handleScroll = () => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;

      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        if (!isScrolledToNewBottom) {
          setPage(prePage => (prePage + 1))
          console.log('滚动到了底部');
          setIsScrolledToNewBottom(true);
        }
      } else {
        setIsScrolledToNewBottom(false);
      }
    };
    scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRankComing = async () => {
    let result = await get('/user/rank/coming')
    let { code, data } = result;
    if (code === 1000) {
      setReward(data)
    }
  }

  const getUserData = async (id) => {
    let result = await get("/user/" + id);
    let { code, data } = result;
    if (code === 1000) {
      setUserInfo(data);
    }
  };

  const getData = async () => {
    if (page === 1) {
      let result = await get(`/club/loot/list?page=${page}&size=${size}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        setClubList(list)
        setTotal(total)
      }
    } else if ((page - 1) * size < total) {
      let result = await get(`/club/loot/list?page=${page}&size=${size}`)
      let { code, data } = result
      if (code === 1000) {
        let { total, list } = data
        let tempList = [...clubList]
        let currentList = tempList.concat([...list])
        setClubList(currentList)
        setTotal(total)
      }
    }
  }

  const handleClick = () => {
    if (cRef.current) {
      cRef.current.togglePopup()
    }
  }

  const getBalance = async () => {
    try {
      if (typeof embeddedWallet !== "undefined") {
        const provider = await embeddedWallet?.getEthereumProvider();
        const balance = await provider?.request({
          method: 'eth_getBalance',
          params: [embeddedWallet?.address, 'latest'], // 'latest' 表示最新的区块
        });
        const balanceInEth = ethers.formatEther(balance);
        setBalance(parseFloat(balanceInEth))
      }
    } catch (error) {
      // console.error(error)
    }
  }

  const markClick = () => {
    if (markRef.current) {
      markRef.current.togglePopup()
    }
  }

  const loot = async () => {
    let postData = {
      attack_address: "0x437159E3f1bE4bd6C8C1e3ec626619E025c22F71",
      definse_address: "0x437159E3f1bE4bd6C8C1e3ec626619E025c22F71",
      pay: "123321",
      reward: "123321"
    }
  }

  return (
    <div className="h-full flex flex-col bg-cover bg-no-repeat"
      style={{ backgroundImage: "url(/images/battle.png)" }}>

      <div className="py-2 px-1 flex items-center bg-black bg-opacity-50 justify-around"
        onClick={() => { navigate('/main/profile') }}>
        <div className="w-12 h-12 flex flex-none items-center justify-center rounded-full border-white overflow-hidden 
        bg-black bg-opacity-50 border-solid border-2 box-border">
          <img src={userInfo.avatar} alt="avatar" className="w-12" />
        </div>
        <WhiteContainer className="flex w-24 relative items-center rounded-full flex-none ml-2">
          <div
            className="left-[0px] top-[0px] absolute h-[28px] w-[28px] flex items-center justify-center text-lg"
            style={{ background: "url(/images/dimond.png) no-repeat center center", backgroundSize: "contain" }}
          >
            <strong>{userInfo.club.level}</strong>
          </div>
          <div className="flex flex-1 pl-[30px] pr-[26px] justify-center">
            {formatNumber(balance)}
          </div>
          <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] absolute right-[4px]' />
        </WhiteContainer>
        <WhiteContainer className="w-20 relative rounded-full flex ml-2 flex-none">
          <div className="bg-button-up p-[1px] flex items-center absolute top-[-1px] left-[-1px] rounded-full h-[30px] w-[30px]">
            <img src="/images/up.png" alt="" />
          </div>
          <div className="flex flex-1 pl-[30px] justify-center">{formatNumber(userInfo.club.up)}</div>
        </WhiteContainer>
        <WhiteContainer className="flex-1 flex relative items-center rounded-full ml-2">
          <div className="bg-[#eaa222] p-[5px] flex items-center absolute left-[-1px] top-[-1px] rounded-full h-[30px] w-[30px]">
            <img src="/images/reward.png" alt="" />
          </div>
          <div className="rounded-full bg-[#eaa222] bg-opacity-20 text-[#eaa222] w-16 pl-6 text-center">
            {formatNumber(userInfo.club.rank)}
          </div>
          <div className="flex-1 flex items-center relative pr-[24px]">
            <div className="flex-1">{formatNumber(userInfo.pool)}</div>
            <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] absolute right-[4px]" />
          </div>
        </WhiteContainer>
      </div>

      <div className="h-12 flex items-center justify-center bg-[#a6a1bb] bg-opacity-35 text-sm">
        Est. $JUMP Points{" "}
        <div className="h-6 p-2 mx-2 rounded-md bg-white bg-opacity-10 border-white border flex items-center justify-center">
          {reward}
        </div>{" "}
        in <CountdownTimer></CountdownTimer>
        <div className="w-4 h-4 p-1 mx-2 rounded-full bg-white bg-opacity-10 border-white border 
        flex items-center justify-center text-xs" onClick={markClick}>
          ?
        </div>
      </div>
      <div className="flex-1 flex flex-col pt-3 pb-2 px-4 overflow-y-auto" ref={scrollRef} >
        {clubList.map((club, index) => (
          <ClubBattle data={club} key={index} index={index} />
        ))}
      </div>
      {/* <Button className="m-2 h-11 text-white"
        style={{ backgroundImage: 'linear-gradient(to right, #e7b02e, #e04353)' }}
        onClick={handleClick}>
        <strong>LOOT A RANDOM CLUB</strong>
      </Button> */}

      <Modal ref={cRef} title="CLUB FOUND" type='center'>
        {clubList.length ?
          <div className="flex flex-col items-center my-4">
            <div className="w-14 h-14 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
              <img src="/images/avatar.png" alt="avatar" />
            </div>
            <div className="text-xs my-2">{clubList[0].owner.name}</div>
            <div className="text-[10px] mt-2 text-gray-500">Power</div>
            <div className="mt-0.1 text-lg mb-4"><strong>{clubList[0].power}</strong></div>
            <div className="rounded-xl h-24 w-full bg-[#2d2b33] flex flex-col p-4 justify-between text-sm">
              <div className="flex flex-row justify-between items-center">
                <div className="text-gray-500">$TTT Pool</div>
                <div className="flex flex-row items-center">
                  <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-1" />
                  {clubList[0].pool}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="text-gray-500">In-Progress Earnings</div>
                <div className="flex flex-row items-center">
                  <img src="/images/ttt.png" alt="" className="w-[20px] h-[20px] mr-1" />
                  0
                </div>
              </div>
            </div>
            <Button className="w-full h-10 text-black flex items-center justify-center my-4"
              onClick={loot}>
              <strong>LOOT THE CLUB</strong>
            </Button>
            <Button className="w-full h-10 bg-white text-black flex items-center justify-center">
              <strong>SHUFFLE</strong>
            </Button>
          </div> :
          null}
      </Modal>

      <Modal ref={markRef} title="LEADERBOARD & $JUMP POINTS" type='center' closeIcon={false}>
        <div className="w-full flex my-4 flex-col flex-1 items-start text-xs overflow-y-auto">
          <div>
            Complete missions & engage in PVP battles to accumulate $TTT to
            climb up the leaderboard.
          </div>
          <div className="mt-2"> Your unclaimed $TTT determines your
            Leaderboard rank, which impacts the daily $JUMP points earnings. More
            $TTT = higher leaderboard rank.
          </div>
          <div className="mt-2">
            Extracting $TTT from the pool resets your rank and start in the bottom of the leaderboard.
          </div>
          <div className="mt-2 board-table ">
            <table className="w-[200px]">
              <thead>
                <tr><th colSpan="2">Ranking Rewards</th></tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.key}</td>
                    <td>{item.value} <span>PTS</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Button className='h-8 text-base' onClick={() => navigate('/main/profile')}>
          <strong>CHECK MY POINTS</strong>
        </Button>
      </Modal>
    </div >
  );
};

export default Battle;


const tableData = [
  { key: "1st", value: "1000" },
  { key: "2nd/3rd", value: "500" },
  { key: "#4~#10", value: "250" },
  { key: "#11~#20", value: "150" },
  { key: "#21~#50", value: "100" },
  { key: "#51~#100", value: "75" },
  { key: "#101~#200", value: "50" },
  { key: "#201~#500", value: "40" },
  { key: "#501~#1000", value: "20" },
  { key: "#1001~#2000", value: "10" },
  { key: "#2001~#5000", value: "5" },
  { key: "#5001~#100k", value: "1" },
]

