import { Routes, Route } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import Home from "./pages/index";
import Main from "./pages/main";
import Chat from "./pages/chat";
import Club from "./pages/club";
import Quest from "./pages/quest";
import Group from "./pages/group";
import Choose from "./pages/choose";
import SingleBattle from "./pages/battle";
import Install from "./pages/install";
import Living from "./pages/liveing";
import Mission from "./pages/views/mission";
import Battle from "./pages/views/battle";
import Explore from "./pages/views/explore";
import Profile from "./pages/views/profile";
import LootFail from "./pages/lootFail";
import Looting from "./pages/looting";
import LootSuccess from "./pages/lootSuccess";
import LootShare from "./pages/lootShare";
import Invite from "./pages/invite";
import Init from "./pages/init";
import Share from "./pages/share";
import SingleMission from "./pages/mission";
import ClaimMission from "./pages/claimMission";

import Test from "./pages/test";

// import PageTransition from './components/PageTransition'

function App() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/main" element={<Main />}>
            <Route path="/main" element={<Mission />} />
            <Route path="/main/battle" element={<Battle />} />
            <Route path="/main/explore" element={<Explore />} />
            <Route path="/main/profile" element={<Profile />} />
          </Route>
          <Route path="/chat/:id" element={<Chat />} />
          <Route path="/quest" element={<Quest />} />
          <Route path="/group" element={<Group />} />
          <Route path="/choose" element={<Choose />} />
          <Route path="/living" element={<Living />} />
          <Route path="/club/:id" element={<Club />} />
          <Route path="/battle" element={<SingleBattle />} />
          <Route path="/lootfail" element={<LootFail />} />
          <Route path="/lootok" element={<LootSuccess />} />
          <Route path="/lootshare" element={<LootShare />} />
          <Route path="/looting" element={<Looting />} />
          <Route path="/install" element={<Install />} />
          <Route path="/test" element={<Test />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/start" element={<Init />} />
          <Route path="/mission" element={<SingleMission />} />
          <Route path="/claim" element={<ClaimMission />} />
          <Route path="/share/:username" element={<Share />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}
export default App;
