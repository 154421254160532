import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { formatNumber } from "../utils/tools";
import { get } from "../utils/request";
import LoadingContent from "../components/LoadingContent";
import {
  Chat,
  ConversationList,
  MessageList,
  TextMessage,
  useClient,
  rootStore,
  ChatroomMessage,
} from "agora-chat-uikit";
// const conversation = {
//   chatType: 'groupChat', // 'singleChat' || 'groupChat'
//   conversationId: '237783669080066', // target user id or group id
//   name: 'soyoco', // target user nickname or group name
//   lastMessage: {},
// };
const ChatPage = () => {
  const navigate = useNavigate();
  const [conversation, setConversation] = useState(null);
  const [userChatInfo, setUserChatInfo] = useState(null);
  const [own, setOwn] = useState(0);
  const [address, setAddress] = useState('')
  const [clubUser, setClubUser] = useState({
    members: 0,
    joined: 0,
  });
  const [userList, setUserList] = useState([]);
  const [userInfo, setUserInfo] = useState({ club: { key: 0 } });
  const [roomInfo, setRoomInfo] = useState({
    name: "",
    twitter_name: "",
    avatar: "/images/avatar.png",
    club: {
      price: 0,
      address: '',
      key: 0,
      price_rate: 0,
      manager: {
        name: "",
      },
    },
  });
  const [loading, setLoading] = useState(true);
  const client = useClient();
  const { id } = useParams();

  useEffect(() => {
    getOwn()
  }, [id])


  const getMembers = async () => {
    let result = await get("/chat/room/" + id);
    if (result.code === 1000) {
      if (result.data !== null) {
        setUserList(result.data);
      }
    }
  };

  const getOwn = async () => {
    let temp = { ...clubUser }
    let membersRes = await get(`/club/members/${id}?page=1&size=1`);
    if (membersRes.code === 1000) {
      let members = membersRes.data.total
      temp.members = members
    }
    let joinedResult = await get(`/club/joined/${id}?page=1&size=1`);
    if (joinedResult.code === 1000) {
      let joined = joinedResult.data.total
      temp.joined = joined
    }
    setClubUser(temp);
  };

  const getChatUserName = (userId) => {
    if (userId == userInfo.account) {
      return userInfo.name;
    } else {
      for (let i = 0; i < userList.length; i++) {
        if (userList[i].account === userId) {
          return userList[i].name;
        }
      }
      return userId;
    }
  };
  const getChatUserAvatar = (userId) => {
    if (userId == userInfo.account) {
      return userInfo.avatar;
    } else {
      for (let i = 0; i < userList.length; i++) {
        if (userList[i].account === userId) {
          return userList[i].avatar;
        }
      }
      return "/images/avatar.png";
    }
  };

  const getRoomInfo = async () => {
    let roomUserInfo = await get("/user/" + id);
    setRoomInfo(roomUserInfo.data);
    if (roomUserInfo.code === 1000) {
      setAddress(roomUserInfo.data.club.address)
      setConversation({
        chatType: "groupChat",
        conversationId: roomUserInfo.data.club.group_id,
        name: roomUserInfo.data.name,
        avatar: roomUserInfo.data.avatar,
        lastMessage: {},
      });
    }
  };

  const getUserToken = async () => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let res = await get("/chat/token");
    setUserInfo(userInfo);
    setUserChatInfo({
      user: userInfo.account,
      agoraToken: res.data,
    });
  };

  useEffect(() => {
    if (conversation !== null && userChatInfo !== null) {
      // console.log(client);
      if (client.user) {
        rootStore.conversationStore.setCurrentCvs(conversation);
        setLoading(false);
      } else {
        client
          .open({
            user: userChatInfo.user,
            agoraToken: userChatInfo.agoraToken,
          })
          .then(() => {
            console.log("join");
            // rootStore.conversationStore.addConversation(conversation);
            setTimeout(() => {
              rootStore.conversationStore.setCurrentCvs(conversation);
              setLoading(false);
            }, 3000);
          });
      }
    }
  }, [conversation, userChatInfo]);

  useEffect(() => {
    getRoomInfo();
    getUserToken();
    getMembers();
  }, []);

  useEffect(() => {
    if (address) getOwnData(address)
  }, [address])

  //持有key数量
  const getOwnData = async (address) => {
    let result = await get("/club/own/" + address);
    let { code, data } = result;
    if (code === 1000) {
      if (data) {
        setOwn(data);
      } else {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo.id !== id) {
          navigate('/club/' + id, { replace: true })
        }
      }
    }
  };

  const renderTxtMsg = (msg) => {
    return (
      <TextMessage
        nickName={getChatUserName(msg.from)}
        // repliedMessage={37854384}
        // renderUserProfile={() => { }}
        // bubbleStyle={{ background: "hsl(135.79deg 88.79% 36.46%)" }}
        shape="square"
        status={msg.status}
        avatar={
          <img
            src={getChatUserAvatar(msg.from)}
            className="h-10 w-10 rounded-full"
          ></img>
        }
        textMessage={msg}
      ></TextMessage>
    );
  };

  const renderMessage = (msg) => {
    if (msg.type === "txt") {
      return renderTxtMsg(msg);
    }
  };

  const linkToX = () => {
    if (roomInfo.twitter_name)
      //  window.location.href = "https://twitter.com/" + roomInfo.twitter_name
      window.open("https://twitter.com/" + roomInfo.twitter_name, "_blank")
  }

  const getTrend = (trend) => {
    let precent = formatNumber(Math.abs(trend) * 100) + "%";
    if (trend >= 0) {
      return (
        <div className="text-[#3fe51c] rounded-full text-right bg-[#2d7e1f] bg-opacity-50 h-[16px] px-1 green-arrow text-[10px]">
          <div className="ml-1 max-w-10 overflow-hidden overflow-ellipsis whitespace-nowrap h-[16px] flex items-center">
            {precent}
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-[#ff6203] rounded-full text-right bg-[#88332c] bg-opacity-50 h-[16px] px-1 red-arrow text-[10px]">
          <div className="ml-1 max-w-10 overflow-hidden overflow-ellipsis whitespace-nowrap">
            {precent}
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className="w-full flex flex-col h-full max-w-[768px] bg-cover bg-no-repeat bg-center overflow-y-hidden"
      style={{ backgroundImage: "url(/images/chat.png)" }}
    >
      {/* <img
        src="/svg/live.svg"
        alt="live"
        className="fixed top-32 right-3 h-14"
        onClick={() => navigate("/living")}
      /> */}
      <div className="h-28 p-[15px] flex items-center bg-black bg-opacity-50">
        <div className="w-4 cursor-pointer mr-2" onClick={() => navigate(-1)}>
          <img className="w-3" src="/images/back.png" alt="back" />
        </div>
        <div className="w-16 h-16 flex items-center justify-center rounded-full border-white overflow-hidden bg-black
         bg-opacity-50 border-solid border-2" onClick={linkToX}>
          <img src={roomInfo.avatar} alt="avatar" className="w-16" />
        </div>
        <div className="flex-1 mx-2 flex flex-col text-xs">
          <div className="flex-1 flex-row flex items-center">
            <div className="max-w-20 overflow-hidden overflow-ellipsis whitespace-nowrap">
              <strong className="text-base">{roomInfo.name}</strong>
            </div>
            {roomInfo.twitter_name ? <div
              style={{
                background: "url(/images/twitter.png) no-repeat center center",
                backgroundSize: "100%",
              }}
              className="p-1 mx-1 flex items-center rounded-full m-[1px] h-[16px] w-[16px] justify-center"
            ></div> : null}
          </div>
          <div className="flex-1">
            <strong>You own <span className="text-purple-400">{own}</span> keys</strong>
          </div>
          <div className="flex-1 flex">
            <div className="my-1 px-2 bg-white bg-opacity-20 rounded-full py-0 relative text-center">
              {clubUser.members} Members,{clubUser.joined} Joined
            </div>
          </div>
          <div className="flex-1">Led by {roomInfo.name}</div>
        </div>
        <div className="ml-2 flex flex-col">
          <div className="flex-1 flex justify-end">
            <Button className="px-4 w-[100px] flex justify-center text-sm py-1" onClick={() => { navigate('/club/' + id) }}>
              <strong>TRADE</strong>
            </Button>
          </div>
          <div className="text-right text-sm mt-1"><strong>{roomInfo.club.price}ETH</strong></div>
          <div className="text-[10px] mt-1 flex justify-end" >
            {getTrend(roomInfo.club.price_rate)}
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col overflow-scroll relative">
        <LoadingContent show={loading ? true : undefined} />
        {userInfo.club.key === 0 ? <div className="w-full fixed bottom-4 left-0 flex justify-center">
          <Button className="my-2 mx-2 h-10 items-center text-white flex justify-center text-sm w-full max-w-[740px]"
            onClick={() => { navigate('/start') }}
            style={{ backgroundImage: 'linear-gradient(to right, #f48351, #c62655)', paddingLeft: 0, paddingRight: 0 }}>
            <strong>CREATE YOUR CLUB TO COMMUNICATE</strong>
          </Button>
        </div> : null}
        {roomInfo.club.key !== 0 ? <ConversationList /> : null}
        {roomInfo.club.key !== 0 ? <Chat
          renderHeader={() => { }}
          renderMessageList={() => (
            <MessageList renderMessage={renderMessage} />
          )}
        /> : null}
      </div>
      {/* <div className="h-16 flex px-4 items-center bg-black bg-opacity-50">
        <div>
          <img className="h-6" src="/images/message.png" alt="msg" />
        </div>
        <div className="flex-1 p-2">
          <textarea className="w-full h-8 resize-none px-2 py-1 bg-blue-300 rounded-full bg-opacity-25"></textarea>
        </div>
        <div>
          <img className="h-6" src="/images/send.png" alt="send" />
        </div>
      </div> */}
    </div>
  );
};

export default ChatPage;
