import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../utils/request'
import { useNavigate } from 'react-router-dom'
import Loadding from '../components/Loading'
const Share = () => {
    const { username } = useParams();
    const navigate = useNavigate()

    const getInviteCode = async () => {
        let res = await get("/user/inviter/" + username.replace("@", ""))
        if (res.code === 1000) {
            navigate("/?invite=" + res.data)
        } else {
            navigate("/")
        }
    }

    useEffect(() => {
        getInviteCode()
    }, [])

    return (
        <div>
            <Loadding show={true} />
        </div>
    );
};

export default Share;