import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { chooselist } from "../utils/mockData";
import { get } from "../utils/request"
import Button from "../components/Button";
import Modal from "../components/Modal";
import { formatNumber, truncateDecimal } from "../utils/tools";


const Choose = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const scrollRef = useRef(null)
    const modalRef = useRef(null)
    const targetClub = location.state?.data;
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [dataList, setDataList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [isScrolledToNewBottom, setIsScrolledToNewBottom] = useState(false);

    useEffect(() => {
        const scrollableDiv = scrollRef.current;
        const handleScroll = () => {
            const scrollTop = scrollableDiv.scrollTop;
            const scrollHeight = scrollableDiv.scrollHeight;
            const clientHeight = scrollableDiv.clientHeight;

            if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
                if (!isScrolledToNewBottom) {
                    setPage(prePage => (prePage + 1))
                    console.log('滚动到了底部');
                    setIsScrolledToNewBottom(true);
                }
            } else {
                setIsScrolledToNewBottom(false);
            }
        };
        scrollableDiv.addEventListener('scroll', handleScroll);
        return () => {
            scrollableDiv.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        getList()
    }, [page])

    const getList = async () => {
        if (page === 1) {
            let result = await get(`/club/manage?page=${page}&size=${size}`)
            let { code, data } = result
            if (code === 1000) {
                let { total, list } = data
                setDataList(list)
                setTotal(total)
            }
        } else if ((page - 1) * size < total) {
            let result = await get(`/club/relate?page=${page}&size=${size}`)
            let { code, data } = result
            if (code === 1000) {
                let { total, list } = data
                let tempList = [...dataList]
                let currentList = tempList.concat([...list])
                setDataList(currentList)
                setTotal(total)
            }
        }
    }

    const handleItemClick = (index) => {
        setSelectedIndex(index)
    }

    const handleClick = () => {
        let from = dataList[selectedIndex]
        let to = targetClub
        navigate('/battle', { state: { from, to } })
    }

    const openModal = () => {
        if (modalRef.current) {
            modalRef.current.togglePopup()
        }
    }

    return (
        <div className="w-full flex flex-col h-full  max-w-[768px] overflow-hidden bg-black">
            <div className="w-full relative flex-none">
                <img src="/images/choosebg.jpg" alt="" className="w-full h-auto" />
                <div className="absolute top-0 left-0 text-white w-full text-lg bg-black bg-opacity-0 py-4">
                    <div className="relative flex items-center justify-center">
                        <strong>CHOOSE YOUR CLUB</strong>
                        <div className="absolute left-[10px]" onClick={() => navigate('/main/battle')}>
                            <img className="w-2" src="/images/back.png" alt="back" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col fixed bottom-2 max-w-[768px] w-full h-[60vh] bg-black rounded-3xl max-h-[70vh]">
                <div className="flex w-full h-12 text-[#808182] flex-none pt-2 px-4">
                    <div className="flex flex-1 justify-center items-center text-xs  mx-2">Club Name</div>
                    <div className="flex flex-1 justify-center items-center text-xs  mx-2">Member's Portifolio</div>
                    <div className="flex flex-1 justify-center items-center text-xs  mx-2">Join Clubs' Portifolio</div>
                    <div className="flex flex-1 justify-center items-center text-xs mx-2">Power
                        {/* <div className="rounded-full h-[18px] w-[18px] border-[#808182] "
                            style={{ border: '1px solid' }} onClick={openModal}>
                            <strong className="text-[#808182]">?</strong>
                        </div> */}
                        <div className="ml-2 border-2 flex items-center justify-center rounded-full h-[20px] w-[20px] border-[#808080]"
                            onClick={openModal}>
                            <strong className="text-[#808080]">?</strong>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col flex-1 px-4 py-1 rounded-t-3xl overflow-y-auto" ref={scrollRef}>
                    {
                        dataList.map((item, index) => (
                            <div className="flex w-full flex-row my-1 rounded-lg py-1" key={index}
                                style={{
                                    background: selectedIndex !== null && selectedIndex === index ?
                                        '#34333b' : '#34333bc7',
                                    border: selectedIndex !== null && selectedIndex === index ? '1px solid #fff' : 'none'
                                }}
                                onClick={() => handleItemClick(index)}>
                                <div className="flex flex-1 justify-center text-sm flex-col items-center">
                                    <div className="w-8 h-8 flex items-center justify-center rounded-full border-white overflow-hidden bg-black bg-opacity-50 border-solid border-2">
                                        <img src={item.owner.avatar} alt="avatar" />
                                    </div>
                                    <div className="flex flex-row text-[10px] px-1 items-center">
                                        <div className="w-10 overflow-hidden overflow-ellipsis whitespace-nowrap">
                                            {item.owner.name}</div>
                                        <img src="/images/energy-yellow.png" alt="energy" className="h-[10px] w-[10px] mx-1" />
                                        <span className="text-[#fcb833]">{item.energy}</span>
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-center text-xs items-center">
                                    <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] mr-1' />
                                    {item.member_portifolio > 1 ? formatNumber(item.member_portifolio) :
                                        truncateDecimal(item.member_portifolio, 5)}
                                </div>
                                <div className="flex flex-1 justify-center text-xs items-center break-all">
                                    <img src="/images/eth.png" alt="" className='h-[20px] w-[20px] mr-1' />
                                    {item.joined_portifolio > 1 ? formatNumber(item.joined_portifolio) :
                                        truncateDecimal(item.joined_portifolio, 5)}
                                </div>
                                <div className="flex flex-1 justify-center items-center text-[#fcb833] break-all">
                                    <strong>{formatNumber(item.power)}</strong>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="h-12 flex-none w-full px-4 mb-2">
                    {dataList.length > 0 ?
                        <Button className={`w-full h-full flex text-sm rounded-full items-center justify-center`}
                            onClick={handleClick} disabled={selectedIndex === null || dataList[selectedIndex].energy < 5} >
                            <strong>SELECT CLUB</strong>
                            <div className="w-[20px] h-[20px] ml-2 mr-1 p-[5px] flex items-center rounded-full bg-[#332626]">
                                <img src="/images/energy-yellow.png" alt="energy" />
                            </div>
                            <strong className="text-[#cc3054]">-5</strong>
                        </Button> :
                        <Button className={`w-full h-full flex text-sm rounded-full items-center justify-center text-white`}
                            style={{ backgroundImage: 'linear-gradient(to right, #f48351, #c62655)', paddingLeft: 0, paddingRight: 0 }}
                            onClick={() => { navigate('/main', { state: { active: 1 } }) }} >
                            <strong>Manage a club to loot</strong>
                        </Button>}
                </div>
            </div>

            <Modal ref={modalRef} closeIcon={false}>
                <div className="flex flex-col items-center my-4 w-full text-xs">
                    <p>Power is determined by the total value of all Members(X) and Joined(Y).</p>
                    <p className="w-full text-[#fcb833]"> X = Total value of all Members</p>
                    <p className="w-full text-[#fcb833]"> Y = Total value of all Joined</p>
                    <p className="w-full text-[#fcb833]">(For Wish accounts, Y=current Key Price)</p>
                    <p className="w-full text-[#fcb833]">N = Number of Club Keys are generated and bought</p>
                    <p className="w-full text-[#fcb833]">T = min(X * min(N/50,1),Y*10)+ Y</p>
                    <p className="w-full text-[#fcb833]">Power = Int(SQRT(50*T)*7))</p>
                    <p className="w-full">1.To unlock X’s full impact, you must ensure at least 50 of your own Club Keys are generated and bought.
                        Failure to meet this requirement will result in a reduced effect.</p>
                    <p className="w-full">2.The smaller value between X and 10Y determines the power calculation.</p>
                    <p className="w-full"> The higher the Power, the higher probability of a successful Loot.</p>
                    <p className="w-full">Once your club engages in a battle, it will be in a cool down period for 5 mins.</p>
                </div>
            </Modal >


        </div >
    );
};

export default Choose;


