import React from "react";
import { ToastContainer, toast } from "react-toastify";

const Test = () => {
  const notify = () => toast.info("Wow so easy!");
  return (
    <div className="w-full h-full bg-red-500">
      <div className="w-10 h-10 bg-green-500 mt-20">
        <button onClick={notify}>Notify!</button>
        <ToastContainer  pauseOnFocusLoss={false} theme="dark" position="top-center" />
      </div>
    </div>
  );
};

export default Test;
